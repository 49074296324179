import React from 'react';

import { TableColumn } from '@hero/ui-kit/tables/Table';
import MenuIcon from '@hero/ui-kit/icons/utility/MenuIcon'; // Nothing better for now...
import ActionButton from '../../../../components/ActionButton';
import { CRMProspectDetails } from '../../types';
import P from '@hero/ui-kit/typography/P';
import { formatDateTime, normalizeDateString } from '@hero/hero-utils/date';
import LockIcon from '@hero/ui-kit/icons/branding/LockIcon';
import SLATimer from '../SLATimer';
import { useNavigate } from 'react-router-dom';
import getStatusLabel from './getStatusLabel';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';

export type ActionHandler = (prospectId: string) => void;

type Render = TableColumn<CRMProspectDetails>['render'];
type GetActionRender = (actionHandler: ActionHandler) => Render;

const statusRender: Render = (prospect) => {
    const label = React.useMemo(() => {
        return getStatusLabel(prospect);
    }, [prospect]);

    return (
        <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
            <P noDefaultMargin strong>
                {label}
            </P>
            {prospect.is_locked ? (
                <div>
                    <LockIcon size="small" />
                </div>
            ) : null}
        </div>
    );
};

const namesRender: GetActionRender = (actionHandler) => (props) => {
    const { first_name, last_name, email } = props.crm_contact;
    const navigateTo = `/crm/${props.prospect_id}/details`;

    const navigate = useNavigate();

    const handleClick = () => {
        if (props.is_locked) {
            actionHandler(props.prospect_id);
        } else {
            navigate(navigateTo);
        }
    };

    return (
        <ClickableOpacity onClick={handleClick} alt="open-prospect" styles={{ cursor: 'pointer' }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    rowGap: '0.4rem'
                }}
            >
                <P noDefaultMargin strong>
                    <span style={{ borderBottom: '1px solid #f26c3a' }}>
                        {first_name} {last_name}
                    </span>
                </P>
                <P noDefaultMargin size="small" styles={{ borderBottom: '1px solid #f26c3a' }}>
                    {email}
                </P>
            </div>
        </ClickableOpacity>
    );
};

const slaTimerRender: Render = (props) => {
    return <SLATimer prospect={props} />;
};

const communicationRender: Render = (props) => {
    const smsSent = props.stats.sms_sent || 0;
    const emailSent = props.stats.email_sent || 0;
    const calls = props.stats.calls || 0;
    const successfulCalls = props.stats.successful_calls || 0;

    const label = `${smsSent > 0 ? `${smsSent} SMS` : ''} 
        ${emailSent > 0 ? `${emailSent} ${emailSent === 1 ? 'email' : 'emails'}` : ''} ${
            calls > 0 ? `${calls} ${calls === 1 ? 'call' : 'calls'}` : ''
        } ${
            successfulCalls > 0
                ? `${successfulCalls} ${successfulCalls === 1 ? 'successful call' : 'successful calls'}`
                : ''
        }`;

    return (
        <P noDefaultMargin strong styles={{ ...(props.sla_started && { color: '#f26c3a' }) }}>
            {label}
        </P>
    );
};

const getNoteRender: Render = (props) => {
    const hasNotes = props.crm_contact.log
        ? props.crm_contact.log.filter((log) => log.action === 'AGENT_NOTE').length > 0
        : false;

    return (
        <ActionButton
            icon={MenuIcon}
            onClick={() => null}
            alt={hasNotes ? 'Edit Note' : 'Add Note'}
            variant={hasNotes ? 'primary' : 'secondary'}
        />
    );
};

type GetTableColumns = (prospectActionHandler: ActionHandler) => TableColumn<CRMProspectDetails>[];
const getTableColumns: GetTableColumns = (prospectActionHandler) => [
    {
        colKey: 'status',
        name: 'Status',
        align: 'left',
        headAlign: 'left',
        render: statusRender
    },
    {
        colKey: 'names',
        name: 'Names',
        align: 'left',
        headAlign: 'left',
        render: namesRender(prospectActionHandler)
    },
    {
        colKey: 'sla_timer',
        name: 'SLA Timer',
        align: 'left',
        headAlign: 'left',
        render: slaTimerRender
    },
    {
        colKey: 'expires_at',
        name: 'Expiring Date',
        align: 'left',
        headAlign: 'left',
        render: (prospect) => (
            <P noDefaultMargin>
                {prospect.expires_at
                    ? formatDateTime(normalizeDateString(prospect.expires_at, true))
                    : 'N/A'}
            </P>
        )
    },
    {
        colKey: 'communication',
        name: 'Communication',
        align: 'left',
        headAlign: 'left',
        render: communicationRender
    },
    {
        colKey: 'notes',
        name: 'Notes',
        align: 'left',
        headAlign: 'left',
        render: getNoteRender
    }
];

export default getTableColumns;
