import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import Button from '@hero/ui-kit/inputs/Button';
import DefaultSelect from '@hero/ui-kit/inputs/DefaultSelect';
import Form from '@hero/ui-kit/inputs/Form';
import TextArea from '@hero/ui-kit/inputs/TextArea';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import React from 'react';

import * as yup from 'yup';
import useProspectAction from '../../../api/useProspectAction';
import InlineLoader from '../../../../../components/InlineLoader';
import QueryInlineError from '../../../../../components/QueryInlineError';

const callDispositionActionsSchema = yup
    .object()
    .shape({
        note: yup.string().nullable().notRequired(),
        action: yup.lazy((val) =>
            !val
                ? yup.string().nullable().notRequired()
                : yup
                      .string()
                      .oneOf(
                          ['called_successful', 'called_left_message', 'called_no_answer'],
                          'Invalid disposition action'
                      )
        ) as unknown as yup.StringSchema<
            'called_successful' | 'called_left_message' | 'called_no_answer',
            object
        >
    })
    .defined();

export type CallDispositionActionsParams = yup.InferType<typeof callDispositionActionsSchema>;

type CallDispositionModalProps = {
    onCancel?: () => void;
    prospectId: string;
} & Pick<ModalProps, 'externalControls'>;

const CallDispositionModal: React.FC<CallDispositionModalProps> = ({
    externalControls,
    onCancel,
    prospectId
}) => {
    const [, setExternalState] = externalControls ?? [];

    const {
        mutateAsync: prospectAction,
        isPending: isActionPending,
        error,
        isSuccess,
        reset
    } = useProspectAction();

    const handleCancel = () => {
        onCancel?.();
        setExternalState && setExternalState(false);
        reset();
    };

    const handleSubmit = (values: CallDispositionActionsParams) => {
        prospectAction({
            prospect_id: prospectId,
            action: values.action,
            ...(values.note && { note: values.note })
        });
    };

    return (
        <Modal
            isCancelable
            onClose={handleCancel}
            externalControls={externalControls}
            type={isSuccess ? 'success' : 'neutral'}
            styles={{ width: '50rem', minWidth: '50rem' }}
        >
            <P centered strong size="large">
                Call Disposition
            </P>

            <Section noDefaultPadding centered>
                {isSuccess ? (
                    <>
                        <P size="large" centered>
                            Call Disposition action is successful.
                        </P>
                        <Button type="button" variant="outlineSecondary" onClick={handleCancel}>
                            Close
                        </Button>
                    </>
                ) : (
                    <>
                        {isActionPending ? (
                            <InlineLoader />
                        ) : (
                            <Form
                                validationSchema={callDispositionActionsSchema}
                                submitFn={handleSubmit}
                                defaultValues={{
                                    action: 'called_successful'
                                }}
                            >
                                <DefaultSelect
                                    name="action"
                                    options={[
                                        { label: 'Connected', value: 'called_successful' },
                                        { label: 'Left Voicemail', value: 'called_left_message' },
                                        { label: 'No Answer', value: 'called_no_answer' }
                                    ]}
                                />
                                <TextArea name="note" displayName="New Note" cols={40} rows={8} />
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        columnGap: '1rem',
                                        marginTop: '1.6rem'
                                    }}
                                >
                                    <Button
                                        type="button"
                                        variant="outlineSecondary"
                                        onClick={handleCancel}
                                        noDefaultMargin
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        disabled={isActionPending}
                                        noDefaultMargin
                                    >
                                        Submit
                                    </Button>
                                </div>

                                {error ? <QueryInlineError centered error={error} /> : null}
                            </Form>
                        )}
                    </>
                )}
            </Section>
        </Modal>
    );
};

export default CallDispositionModal;
