import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import * as React from 'react';
import { useReactivationFlowContext } from './context/ReactivationFlowProvider';

const BundlePlanDetails: React.FC = () => {
    const { planDetails } = useReactivationFlowContext();

    const marginTop = useComponentMargin({ marginTop: 'regular' });

    const margin = {
        marginBottom: '1.4rem',
        marginTop: '1.4rem'
    };

    return (
        <Section noDefaultPadding styles={{ textAlign: 'left', ...marginTop }} width="full">
            <Container gridTemplateColumns="1fr 1fr">
                <div>
                    <P size="large" noDefaultMargin>
                        PLAN CANCELLED DATE
                    </P>
                    <P size="large" strong noDefaultMargin>
                        {planDetails.planCancelledDate}
                    </P>
                </div>
                <div>
                    <P size="large" noDefaultMargin>
                        PLAN REACTIVATION DATE
                    </P>
                    <P size="large" strong noDefaultMargin>
                        {planDetails.planReactivationDate}
                    </P>
                </div>
            </Container>
            <hr style={{ ...margin }} />
            <P>
                Member has prepaid until <strong>{planDetails.prepaidEndDate}</strong>
            </P>
            <P noDefaultMargin>
                <strong>Note:</strong> Original prepaid period does not change, regardless of the
                inactive period while membership was cancelled.
            </P>
            <hr style={{ ...margin }} />
        </Section>
    );
};

export default BundlePlanDetails;
