import * as React from 'react';
import DefaultLayout from '../../../../components/DefaultLayout';
import Table from '@hero/ui-kit/tables/Table';
import usePagerProps from '../../../../hooks/usePagerProps';
import getTableColumns from '../utils/getTableColumns';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import getQueryParams from '@hero/hero-utils/getQueryParams';
import SearchForm from '../../../../components/SearchForm';
import { CRMProspectDetails } from '../../types';
import useGetCRMProspectList from '../../api/useGetCRMProspectList';
import ActiveFilter from './ActiveFilter';
import LimitItems from '../../../../components/LimitItems';
import InlineLoader from '../../../../components/InlineLoader';
import Modal from '@hero/ui-kit/components/Modal';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import { useNavigate } from 'react-router-dom';

const CRMUsersListInner: React.FC = () => {
    const navigate = useNavigate();
    const {
        offset = 0,
        limit = 20,
        search,
        active_only
    } = getQueryParams<{
        offset?: number;
        limit?: number;
        search?: string;
        active_only?: string;
    }>();

    const activeOnly = active_only === 'true' ? true : false;

    const {
        data: prospectsResponse,
        isFetching: isFetchingList,
        isLoading: isLoadingList
    } = useGetCRMProspectList({
        limit,
        offset,
        ...(search?.length && { search }),
        active_only: activeOnly
    });

    const count = prospectsResponse?.data.total || 0;

    const pagerProps = usePagerProps({
        total_count: count,
        limit,
        offset
    });

    const [lockedProspectId, setLockedProspectId] = React.useState<string | null>(null);
    const [showLockedProspectModal, setShowLockedProspectModal] = React.useState(false);

    const handleLockedProspect = (prospectId: string) => {
        setLockedProspectId(prospectId);
        setShowLockedProspectModal(true);
    };

    const handleCancel = () => {
        setLockedProspectId(null);
        setShowLockedProspectModal(false);
    };

    const handleForceView = () => {
        if (lockedProspectId) {
            // navigate to the prospect details page
            navigate(`/crm/${lockedProspectId}/details?force_view=true`);
        }
    };

    const tableColumns = React.useMemo(() => getTableColumns(handleLockedProspect), []);

    return (
        <DefaultLayout>
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <H role="h5">CRM</H>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '2rem'
                    }}
                >
                    <LimitItems
                        isLoading={isLoadingList}
                        limit={limit}
                        offset={offset}
                        total_count={count}
                    />
                    <SearchForm placeholder="Search by  email or first/last name" />
                    <ActiveFilter />
                </div>
                <div style={{ overflowX: 'auto', paddingBottom: '2.4rem' }}>
                    <Table<CRMProspectDetails>
                        columns={tableColumns}
                        data={prospectsResponse?.data.prospects || []}
                        rowKey="prospect_id"
                        emptyValues="-"
                        isLoading={isFetchingList}
                        pagerProps={pagerProps}
                    />
                </div>
            </Section>
            <Modal
                isCancelable
                onClose={handleCancel}
                externalControls={[showLockedProspectModal, setShowLockedProspectModal]}
            >
                <div>
                    <P noDefaultMargin size="large" strong centered>
                        Prospect is locked by another agent!
                    </P>
                    <P noDefaultMargin centered>
                        Are you sure you want to override this?
                    </P>

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: '1rem',
                            marginTop: '1.6rem',
                            justifyContent: 'center'
                        }}
                    >
                        <Button
                            type="button"
                            variant="outlineSecondary"
                            onClick={handleCancel}
                            noDefaultMargin
                        >
                            Cancel
                        </Button>
                        <Button type="button" onClick={handleForceView} noDefaultMargin>
                            Override
                        </Button>
                    </div>
                </div>
            </Modal>
        </DefaultLayout>
    );
};

const CRMUsersList: React.FC = () => {
    const [isMounted, setIsMounted] = React.useState(false);

    // this delay is used to wait for release lock to be saved in BE
    React.useEffect(() => {
        const timer = setTimeout(() => {
            setIsMounted(true);
        }, 350);

        return () => clearTimeout(timer);
    }, []);

    return isMounted ? (
        <CRMUsersListInner />
    ) : (
        <DefaultLayout>
            <InlineLoader />
        </DefaultLayout>
    );
};

export default CRMUsersList;
