import * as React from 'react';
import { CancellationFlowState } from './context/cancellationStateMachine';
import StepDetails from './StepDetails';
import StepReview from './StepReview';
import StepShipping from './StepShipping';
import SuccessReport from './SuccessReport';
import StepMitigation from './StepMitigation';
import MitigationSuccess from './MitigationSuccess';
import StepMitigationOffers from './StepMitigationOffers';
import StepPlanOffers from './StepPlanOffers';
import StepCollectPaymentMethod from './StepCollectPaymentMethod';
import RTMtoD2CSuccessReport from './RTMtoD2CSuccessReport';
import DisabledCancel from './DisabledCancel';
import StepDischargeWarning from './StepDischargeWarning';

interface ModalStepsProps {
    state?: CancellationFlowState;
    handleEndFlow: () => void;
}

const ModalSteps: React.FC<ModalStepsProps> = ({ state, handleEndFlow }) => {
    if (state === 'discharge-warning') {
        return <StepDischargeWarning />;
    }
    if (state === 'details') {
        return <StepDetails />;
    }
    if (state === 'mitigation-offers') {
        return <StepMitigationOffers />;
    }
    if (state === 'plan-offers') {
        return <StepPlanOffers />;
    }
    if (state === 'collect-payment-method') {
        return <StepCollectPaymentMethod />;
    }
    if (state === 'rtm-to-d2c-success') {
        return <RTMtoD2CSuccessReport onClose={handleEndFlow} />;
    }
    if (state === 'mitigation') {
        return <StepMitigation />;
    }
    if (state === 'mitigation-success') {
        return <MitigationSuccess onClose={handleEndFlow} />;
    }
    if (state === 'review') {
        return <StepReview />;
    }
    if (state === 'shipping') {
        return <StepShipping onClose={handleEndFlow} />;
    }
    if (state === 'success') {
        return <SuccessReport handleEndFlow={handleEndFlow} />;
    }
    if (state === 'disabled-cancel') {
        return <DisabledCancel onClose={handleEndFlow} />;
    }
    return null;
};

export default ModalSteps;
