import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    fetchMembershipPlans,
    membershipPlansReset
} from '@hero/redux-data/backoffice/membershipPlans/actionCreators';
import {
    areMembershipPlansLoadingSelector,
    membershipPlansErrorSelector,
    activeMembershipPlansSelector
} from '@hero/redux-data/backoffice/membershipPlans/selectors';
import Loader from '@hero/ui-kit/graphics/Loader';
import Option from '@hero/ui-kit/inputs/Option';
import Select from '@hero/ui-kit/inputs/Select';
import InlineError from '../../../components/InlineError';

export const usedPlans = {
    'basic-extended-trial': 'Monthly $44.99',
    'commitment-monthly-12months': 'Annual $34.99 / paid monthly / 12 month commitment',
    'commitment-monthly-24months': '24 Months $29.99 / paid monthly / 24 month commitment',
    basic: 'Basic Monthly',
    annual: 'Annual (Upfront)',
    biannual: 'Basic 24 Months',
    'legacy-unlimited-free': 'Legacy Unlimited Free',
    'unlimited-free': 'Unlimited free',
    'new-bundle-1-year': 'Bundle 12-month plan',
    'new-bundle-2-year': 'Bundle 24-month plan',
    'new-bundle-3-year': 'Bundle 36-month plan',
    undefined: 'Storefront Key Not Found'
};

type UsedPlans =
    | 'basic'
    | 'basic-extended-trial'
    | 'annual'
    | 'commitment-monthly-12months'
    | 'biannual'
    | 'commitment-monthly-24months'
    | 'unlimited-free'
    | 'legacy-unlimited-free'
    | 'new-bundle-1-year'
    | 'new-bundle-2-year'
    | 'new-bundle-3-year';

const PlanPicker: React.FC<{ filterPlans?: UsedPlans[] }> = ({ filterPlans = [] }) => {
    const dispatch = useDispatch();
    const plans = useSelector(activeMembershipPlansSelector, shallowEqual);
    const isFetchingPlans = useSelector(areMembershipPlansLoadingSelector);
    const { error, errorMessage, originalMessage } = useSelector(
        membershipPlansErrorSelector,
        shallowEqual
    );

    React.useEffect(() => {
        dispatch(fetchMembershipPlans());

        return () => {
            dispatch(membershipPlansReset());
        };
    }, [dispatch]);

    const filteredPlans = React.useMemo(() => {
        return plans.filter((plan) =>
            filterPlans.length
                ? !!filterPlans.find((planKey) => planKey === plan.storefront_key)
                : Object.keys(usedPlans).includes(plan.storefront_key || '')
        );
    }, [plans, filterPlans]);

    if (error) {
        return <InlineError message={`${errorMessage}. ${originalMessage}`} />;
    }

    return isFetchingPlans ? (
        <Loader styles={{ width: '80px', height: '60px' }} />
    ) : (
        <Select
            name="plan"
            displayName="Membership Plan"
            styles={{ width: '42.4rem' }}
            noExtraMargin
            defaultValue="basic-extended-trial"
        >
            {filteredPlans.map((filteredPlan) => (
                <Option
                    key={filteredPlan.storefront_key}
                    value={filteredPlan.storefront_key || ''}
                    labelText={usedPlans[`${filteredPlan.storefront_key}`]}
                />
            ))}
        </Select>
    );
};

export default PlanPicker;
