export const setPlan = 'set-plan';
export const membership = 'membership';
export const invoices = 'invoices';
export const unlockPaymentFailed = 'unlock-payment-failed';
export const subscriptionChanges = 'subscription-changes';
export const membershipCancel = 'membership-cancel';
export const rtmTransferOptions = 'rtm-transfer-options';
export const cancellationOptions = 'cancellation-options';
export const cancellationDetails = 'cancellation-details';
export const cancellationFeePreview = 'cancellation-fee-preview';
export const cancellationRefundPreview = 'cancellation-refund-preview';
export const paymentMethod = 'payment-method';
