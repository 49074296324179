import React from 'react';
import Container from '@hero/ui-kit/layout/Container';
import Input from '@hero/ui-kit/inputs/Input';
import Select from '@hero/ui-kit/inputs/Select';
import Option from '@hero/ui-kit/inputs/Option';
import StandaloneCheckbox from '@hero/ui-kit/inputs/StandaloneCheckbox';
import useRmaEnums from '../../../pages/Rma/utils/useRmaEnums';
import ConfirmSection from './ConfirmSection';
import PickupLocation from './PickupLocation';
import ReturnLocationSelect from '../../../pages/Rma/components/ReturnLocationSelect';
import { RmaEnums } from '@hero/hero-types';
import { useFormContext } from '@hero/ui-kit/inputs/Form';
import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';
import ConfirmationModal from '../../../components/ConfirmationModal';
import useVoidRmaRequest from '../../../pages/Rma/api/useVoidRmaRequest';
import QueryInlineError from '../../../components/QueryInlineError';

type Props = {
    buttonLabel?: string;
    state?: string;
    isManual?: boolean;
    rmaEnums?: RmaEnums;
    rmaId?: number;
    onClose?: () => void;
};

const ReturnShippingFormFields: React.FC<Props> = ({
    buttonLabel = 'Complete RMA',
    isManual = false,
    state,
    rmaEnums,
    rmaId,
    onClose
}) => {
    const { shippingOptionOptions, returnLocations } = useRmaEnums(rmaEnums);

    const { setValue } = useFormContext();

    const handleReturnLocationChange = React.useCallback(
        (value: string) => {
            setValue('return_location', value, {
                shouldValidate: true,
                shouldDirty: true
            });
        },
        [setValue]
    );

    const [showConfirmVoid, setShowConfirmVoid] = React.useState(false);

    const handleShowConfirmVoid = () => {
        setShowConfirmVoid(true);
    };

    const handleCloseConfirmVoid = () => {
        setShowConfirmVoid(false);
    };

    const { mutateAsync: voidRma, reset, isPending: isVoidPending, error } = useVoidRmaRequest();

    const handleVoid = () => {
        if (rmaId) {
            voidRma({ id: rmaId })
                .then(() => {
                    reset();
                    handleCloseConfirmVoid();
                    onClose && onClose();
                })
                .catch(() => {
                    reset();
                });
        }
    };

    return (
        <>
            <Container
                gridTemplateColumns="1fr 1fr"
                gridColumnGap="regular"
                styles={{ alignItems: 'start' }}
            >
                <ReturnLocationSelect
                    onChange={handleReturnLocationChange}
                    returnLocations={returnLocations}
                />
                <Select
                    defaultValue={
                        shippingOptionOptions && shippingOptionOptions[0]
                            ? shippingOptionOptions[0][0]
                            : undefined
                    }
                    name="return_shipping_option"
                    displayName="Shipping Option"
                    visibleLimit={5}
                    noExtraMargin
                >
                    {shippingOptionOptions.map(([value, label]) => {
                        return <Option key={value} value={value} labelText={label} />;
                    })}
                </Select>
            </Container>
            <StandaloneCheckbox
                name="is_manual_return_shipping"
                labelText="Create shipping manually"
            />
            {isManual ? (
                <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                    <Input name="return_shipping_label_url" displayName="Shipping Label URL" />
                    <Input name="return_tracking_number" displayName="Tracking Number" />
                </Container>
            ) : (
                <PickupLocation state={state} />
            )}
            <ConfirmSection buttonLabel={buttonLabel} />

            {rmaId ? (
                <Section noDefaultPadding centered>
                    <Button
                        width="large"
                        variant="secondary"
                        secondaryBorderColor="primary"
                        onClick={handleShowConfirmVoid}
                    >
                        Void RMA
                    </Button>

                    {error ? <QueryInlineError centered error={error} /> : null}
                </Section>
            ) : null}

            <ConfirmationModal
                disabled={isVoidPending}
                externalControls={[showConfirmVoid, setShowConfirmVoid]}
                title={'Void Rma'}
                description={'Are you sure you want to void?'}
                onConfirm={handleVoid}
            />
        </>
    );
};

export default React.memo(ReturnShippingFormFields, (prevProps, nextProps) => {
    return (
        prevProps.isManual === nextProps.isManual && prevProps.buttonLabel === nextProps.buttonLabel
    );
});
