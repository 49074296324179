import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CRMProspectList from './components/List/CRMProspectList';
import CRMProspectDetails from './components/Details/CRMProspectDetails';

const CRMRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<CRMProspectList />} />
            <Route path="list" element={<CRMProspectList />} />
            <Route path=":prospect_id/details" element={<CRMProspectDetails />} />
        </Routes>
    );
};

export default CRMRoutes;
