import * as React from 'react';
import { CRMProspectDetails } from '../../types';
import P from '@hero/ui-kit/typography/P';
import {
    differenceInSeconds,
    differenceInMinutes,
    differenceInHours,
    differenceInDays
} from 'date-fns';

interface SLATimerProps {
    prospect: CRMProspectDetails;
}

const SLATimer: React.FC<SLATimerProps> = ({ prospect }) => {
    const now = new Date();
    const slaDate = prospect.sla_start_time ? new Date(Date.parse(prospect.sla_start_time)) : now;

    const formatSlaDate = (startDate: Date, endDate: Date): string => {
        const secondsDifference = differenceInSeconds(endDate, startDate);

        const days = Math.abs(differenceInDays(endDate, startDate));
        const hours = Math.abs(differenceInHours(endDate, startDate) % 24);
        const minutes = Math.abs(differenceInMinutes(endDate, startDate) % 60);
        const seconds = Math.abs(differenceInSeconds(endDate, startDate) % 60);

        let formattedTime = '';

        if (days > 0) {
            formattedTime += `${days} day${days > 1 ? 's' : ''}`;
        }

        if (hours > 0) {
            if (formattedTime) formattedTime += ', ';
            formattedTime += `${hours} hour${hours > 1 ? 's' : ''}`;
        }

        if (minutes > 0 && days === 0) {
            if (formattedTime) formattedTime += ', ';
            formattedTime += `${minutes} minute${minutes > 1 ? 's' : ''}`;
        }

        if (seconds > 0 && days === 0 && hours === 0 && minutes === 0) {
            if (formattedTime) formattedTime += ', ';
            formattedTime += `${seconds} second${seconds > 1 ? 's' : ''}`;
        }

        if (secondsDifference < 0) {
            return `${formattedTime}`;
        }

        return formattedTime;
    };

    const formattedSlaDate = formatSlaDate(slaDate, now);

    return (
        <P noDefaultMargin strong styles={{ ...(prospect.sla_started && { color: '#f26c3a' }) }}>
            {formattedSlaDate}
        </P>
    );
};

export default SLATimer;
