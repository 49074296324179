import { MembershipReactivationDetails } from '@hero/hero-types';
import formatDollarAmount from '@hero/hero-utils/currency';
import P from '@hero/ui-kit/typography/P';
import * as React from 'react';

interface UnpaidInvoicesProps {
    invoices: MembershipReactivationDetails['unpaid_preview_invoices'];
}

const UnpaidInvoices: React.FC<UnpaidInvoicesProps> = ({ invoices }) => {
    const padding = '0 6px';

    return (
        <>
            <P centered size="large" noDefaultMargin strong>
                Unpaid Invoices
            </P>
            {invoices?.length === 0 ? (
                <P centered noDefaultMargin>
                    No unpaid invoices found
                </P>
            ) : (
                <table
                    style={{
                        fontSize: '16px',
                        borderCollapse: 'separate',
                        borderSpacing: '1em'
                    }}
                >
                    <thead>
                        <tr style={{ textAlign: 'left' }}>
                            <th style={{ padding }}>Invoice Number</th>
                            <th style={{ padding }}>Invoice Status</th>
                            <th style={{ padding }}>Amount</th>
                            <th style={{ padding }}>Invoice Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices?.map((invoice) => (
                            <tr style={{ fontSize: '14px' }} key={invoice.invoice_number}>
                                <td style={{ padding }}>{invoice.invoice_number}</td>
                                <td style={{ padding, textTransform: 'uppercase' }}>
                                    {invoice.invoice_status}
                                </td>
                                <td style={{ padding }}>
                                    {formatDollarAmount(invoice.amount, true)}
                                </td>
                                <td style={{ padding }}>
                                    {invoice.invoice_date_start}-{invoice.invoice_date_end}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </>
    );
};

export default UnpaidInvoices;
