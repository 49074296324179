import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import Button from '@hero/ui-kit/inputs/Button';
import { useReactivationFlowContext } from '../context/ReactivationFlowProvider';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import BundlePlanDetails from '../BundlePlanDetails';
import OtherPlanDetails from '../OtherPlanDetails';

export const Details = () => {
    const {
        goToFOP,
        onCloseReactivationFlow,
        detailsBtnConfirm,
        detailsBtnLabel,
        showBundleScreen,
        goToChangePlan
    } = useReactivationFlowContext();

    const marginTop = useComponentMargin({ marginTop: 'regular' });
    const marginLeft = useComponentMargin({ marginLeft: 'regular' });

    return (
        <>
            {showBundleScreen ? <BundlePlanDetails /> : <OtherPlanDetails />}

            <Section centered noDefaultPadding>
                <div style={{ display: 'flex', alignContent: 'center', ...marginTop }}>
                    <Button onClick={detailsBtnConfirm}>{detailsBtnLabel}</Button>
                    <Button
                        variant="secondary"
                        onClick={onCloseReactivationFlow}
                        styles={marginLeft}
                    >
                        Cancel Reactivation
                    </Button>
                </div>
                <Button
                    onClick={goToFOP}
                    variant="inverted"
                    size="regular"
                    styles={marginTop}
                    noDefaultMargin
                >
                    Add new credit card
                </Button>
                <Button
                    onClick={goToChangePlan}
                    variant="secondary"
                    size="regular"
                    styles={marginTop}
                    noDefaultMargin
                >
                    Back
                </Button>
            </Section>
        </>
    );
};
