// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gH2yNlabF_6iIzDLUN57 {
    position: relative;
    height: 3.6rem;
}
.t7uPZxslPP5sMTM_UCHZ {
    position: absolute;
    top: calc(120%);
    background: white !important;
    width: 24rem;
    z-index: 999999999;
}
.feqdh1SmiBlT_R4vDv2r {
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 100%;
}
.feqdh1SmiBlT_R4vDv2r:hover {
    background-color: var(--color-neutrals-borderBeta) !important;
}
.kJVnqfr9D2lfQFNC6MUD {
    background-color: var(--color-neutrals-borderBeta) !important;
}
.KfyuKeO83PltGYRPJrfj {
    padding-top: 1.4rem;
    padding-left: 1.6rem;
}
.AeACQkZbuefGQvw82EEz {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin: 1.2rem 0;
    padding: 0 1.6rem !important;
    outline: none !important;
}
.msNsxgtc0JRqswCzLmOd p {
    color: var(--color-primary-alpha) !important;
    font-weight: bold !important;
}
`, "",{"version":3,"sources":["webpack://./components/LimitItems/style.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;AAClB;AACA;IACI,kBAAkB;IAClB,eAAe;IACf,4BAA4B;IAC5B,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,cAAc;IACd,mBAAmB;AACvB;AACA;IACI,6DAA6D;AACjE;AACA;IACI,6DAA6D;AACjE;AACA;IACI,mBAAmB;IACnB,oBAAoB;AACxB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,4BAA4B;IAC5B,wBAAwB;AAC5B;AACA;IACI,4CAA4C;IAC5C,4BAA4B;AAChC","sourcesContent":[".filterStatus {\n    position: relative;\n    height: 3.6rem;\n}\n.filterStatusDropdown {\n    position: absolute;\n    top: calc(120%);\n    background: white !important;\n    width: 24rem;\n    z-index: 999999999;\n}\n.filterStatusToggle {\n    width: 3.6rem;\n    height: 3.6rem;\n    border-radius: 100%;\n}\n.filterStatusToggle:hover {\n    background-color: var(--color-neutrals-borderBeta) !important;\n}\n.openLimitBox {\n    background-color: var(--color-neutrals-borderBeta) !important;\n}\n.headerLabel {\n    padding-top: 1.4rem;\n    padding-left: 1.6rem;\n}\n.filterStatusItem {\n    display: flex;\n    align-items: center;\n    column-gap: 1rem;\n    margin: 1.2rem 0;\n    padding: 0 1.6rem !important;\n    outline: none !important;\n}\n.selected p {\n    color: var(--color-primary-alpha) !important;\n    font-weight: bold !important;\n}\n"],"sourceRoot":""}]);
// Exports
export var filterStatus = `gH2yNlabF_6iIzDLUN57`;
export var filterStatusDropdown = `t7uPZxslPP5sMTM_UCHZ`;
export var filterStatusToggle = `feqdh1SmiBlT_R4vDv2r`;
export var openLimitBox = `kJVnqfr9D2lfQFNC6MUD`;
export var headerLabel = `KfyuKeO83PltGYRPJrfj`;
export var filterStatusItem = `AeACQkZbuefGQvw82EEz`;
export var selected = `msNsxgtc0JRqswCzLmOd`;
export default ___CSS_LOADER_EXPORT___;
