import * as React from 'react';
import DefaultLayout from '../../../../components/DefaultLayout';
import useGetDischargedMembers from '../../api/useGetDischargedMembers';
import { DischargeMember } from '../../types';
import Table from '@hero/ui-kit/tables/Table';
import usePagerProps from '../../../../hooks/usePagerProps';
import getTableColumns from '../utils/getTableColumns';
import MemberNoteModal from '../MemberNoteModal';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import CircularButton from '@hero/ui-kit/inputs/CircularButton';
import DownloadIcon from '@hero/ui-kit/icons/utility/DownloadIcon';
import useGetDischargedMembersCSV from '../../api/useGetDischargedMembersCSV';
import useSaveDischargedMemberNote from '../../api/useSaveDischargedMemberNote';
import PageSizeForm from '../../../../components/PageSizeForm';
import getQueryParams from '@hero/hero-utils/getQueryParams';
import SearchForm from '../../../../components/SearchForm';

interface DischargedMembersListProps {}

const DischargedMembersList: React.FC<DischargedMembersListProps> = () => {
    const {
        offset = 0,
        limit = 10,
        search
    } = getQueryParams<{ offset?: number; limit?: number; search?: string }>();

    const {
        data: membersResponse,
        isLoading: isFetchingList,
        refetch: refetchMembers
    } = useGetDischargedMembers({ limit, offset, search });

    const pagerProps = usePagerProps({
        total_count: membersResponse?.data.total_count || 0,
        limit,
        offset
    });

    const [noteMemberId, setNoteMemberId] = React.useState<number | null>(null);

    const handleNoteAction = (memberId: number) => {
        setNoteMemberId(memberId);
    };

    const tableColumns = React.useMemo(() => getTableColumns(handleNoteAction), [handleNoteAction]);

    const { mutateAsync, isPending } = useSaveDischargedMemberNote();

    const handleSaveNote = (member: DischargeMember, notes: string) => {
        mutateAsync({ member_id: member.member_id, note: notes })
            .then(() => {
                refetchMembers();
            })
            .catch(() => {});
    };

    const downloadMembersCsv = useGetDischargedMembersCSV();

    const handleDownloadMembers = () => {
        downloadMembersCsv();
    };

    const noteMember = membersResponse?.data.discharged_members.find(
        (m) => m.member_id === noteMemberId
    );

    return (
        <DefaultLayout>
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start'
                    }}
                >
                    <H role="h5">Retention Queue</H>
                    <CircularButton
                        variant="secondary"
                        isLink={false}
                        onClick={handleDownloadMembers}
                    >
                        <DownloadIcon size="utility" id={'download-csv'} />
                    </CircularButton>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '2rem'
                    }}
                >
                    <PageSizeForm />
                    <SearchForm placeholder="Search by email or serial" />
                </div>
                <Table<DischargeMember>
                    columns={tableColumns}
                    data={membersResponse?.data.discharged_members || []}
                    rowKey="hero_user_id"
                    emptyValues="-"
                    isLoading={isFetchingList}
                    pagerProps={pagerProps}
                />
                <MemberNoteModal
                    member={noteMember}
                    onCancel={() => setNoteMemberId(null)}
                    onConfirm={handleSaveNote}
                    disabled={isPending}
                />
            </Section>
        </DefaultLayout>
    );
};

export default DischargedMembersList;
