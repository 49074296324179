import * as React from 'react';
import DefaultLayout from '../../../../components/DefaultLayout';
import Section from '@hero/ui-kit/layout/Section';
import BackButton from '../../../../components/BackButton';
import { useNavigate, useParams } from 'react-router-dom';
import useGetCRMProspectDetails from '../../api/useGetCRMProspectDetails';
import InlineLoader from '../../../../components/InlineLoader';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import DeactivateModal from './modals/DeactivateModal';
import PostponeSLATimerModal from './modals/PostponeSLATimer';
import SendCommunicationModal from './modals/SendCommunicationModal';
import CallDispositionModal from './modals/CallDispositionModal';
import H from '@hero/ui-kit/typography/H';
import AddNoteForm from './AddNoteForm';
import LogList from './LogList';
import SLATimer from '../SLATimer';
import useProspectAction from '../../api/useProspectAction';
import getStatusLabel from '../utils/getStatusLabel';
import useInterval from '../../../../hooks/useInterval';

const DetailRow: React.FC<{ children: React.ReactNode; gap?: string }> = ({
    children,
    gap = '3rem'
}) => <div style={{ display: 'flex', alignItems: 'center', columnGap: gap }}>{children}</div>;

const CRMUsersDetails: React.FC = () => {
    const navigate = useNavigate();
    const [showDeactivate, setShowDeactivate] = React.useState(false);
    const [showPostponeSLA, setShowPostponeSLA] = React.useState(false);
    const [showSendCommunication, setShowSendCommunication] = React.useState(false);
    const [showCallDisposition, setShowCallDisposition] = React.useState(false);
    const [log, setLog] = React.useState('prospect');

    const { mutateAsync: prospectAction } = useProspectAction();

    const { prospect_id, force_view } = useParams<{ prospect_id: string; force_view?: string }>();

    // call release lock on unmount
    React.useEffect(() => {
        return () => {
            const releaseLock = async () => {
                if (prospect_id) {
                    console.log('releasing lock called');
                    await prospectAction({ prospect_id, action: 'release_lock' });
                }
            };
            releaseLock();
        };
    }, [prospect_id, prospectAction]);

    useInterval(() => {
        if (prospect_id) {
            prospectAction({ prospect_id, action: 'refresh_lock' });
        }
    }, 60000);

    const handleNavigate = () => {
        navigate('/crm/list');
    };

    const {
        data: prospectResponse,
        isFetching: isProspectFetching,
        error
    } = useGetCRMProspectDetails({
        prospect_id: prospect_id || '',
        ...(force_view === 'true' && { force_view: true })
    });

    const errorMessages = error?.response?.data?.errors || [];

    const prospect = prospectResponse?.data.prospect;

    const communication = prospectResponse?.data.communication;

    const isProspectActive = prospect?.is_active;

    const logs = log === 'prospect' ? prospect?.log || [] : prospect?.crm_contact.log || [];

    if (errorMessages.length) {
        const isLockedByAnotherAgent = errorMessages.includes('prospect_locked_by_another_agent');

        return (
            <DefaultLayout>
                <Section
                    subtheme={['regular', 'background']}
                    paddingVertical="small"
                    paddingHorizontal="regular"
                >
                    {isLockedByAnotherAgent ? (
                        <>
                            <P centered strong size="large">
                                Prospect is locked by another agent
                            </P>

                            <Button
                                size="small"
                                onClick={() =>
                                    navigate(`/crm/prospects/${prospect_id}?force_view=true`)
                                }
                            >
                                Override lock and view
                            </Button>

                            <Button
                                variant="outlineSecondary"
                                size="small"
                                onClick={() => navigate('/crm/list')}
                            >
                                Back to CRM list
                            </Button>
                        </>
                    ) : (
                        <>
                            <P centered strong size="large">
                                Error fetching prospect details
                            </P>
                            {errorMessages.length > 0 && (
                                <ul>
                                    {errorMessages.map((message, index) => (
                                        <li key={index}>{message}</li>
                                    ))}
                                </ul>
                            )}
                        </>
                    )}
                </Section>
            </DefaultLayout>
        );
    }

    return (
        <DefaultLayout>
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                {isProspectFetching ? (
                    <InlineLoader />
                ) : (
                    <>
                        <DetailRow gap="5rem">
                            <BackButton onBack={handleNavigate} alt="back to list" />
                            {prospect ? (
                                <H
                                    role="h4"
                                    noDefaultMargin
                                >{`${getStatusLabel(prospect)} - ${prospect?.crm_contact.first_name} ${prospect?.crm_contact.last_name}`}</H>
                            ) : null}

                            <Button
                                disabled={!isProspectActive}
                                size="small"
                                onClick={() => setShowDeactivate(true)}
                            >
                                Deactivate
                            </Button>

                            {!isProspectActive ? (
                                <P noDefaultMargin strong>
                                    ⚠️ Prospect Inactive!
                                </P>
                            ) : null}
                        </DetailRow>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'start',
                                gap: '10rem',
                                marginTop: '3rem',
                                marginBottom: '5rem'
                            }}
                        >
                            <div
                                style={{
                                    flexBasis: '40%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    rowGap: '2rem'
                                }}
                            >
                                <DetailRow>
                                    <P strong size="large" noDefaultMargin>
                                        Is existing member?
                                    </P>
                                    <P
                                        noDefaultMargin
                                        styles={{
                                            ...(prospect?.is_existing_member && {
                                                color: '#f26c3a'
                                            })
                                        }}
                                    >
                                        {prospect?.is_existing_member ? 'YES' : 'NO'}
                                    </P>
                                </DetailRow>
                                <DetailRow>
                                    <P strong size="large" noDefaultMargin>
                                        Email
                                    </P>
                                    <P noDefaultMargin>{prospect?.crm_contact.email || 'N/A'}</P>
                                </DetailRow>
                                <DetailRow>
                                    <P strong size="large" noDefaultMargin>
                                        Phone
                                    </P>
                                    <P noDefaultMargin>{prospect?.crm_contact.phone || 'N/A'}</P>
                                </DetailRow>
                                <DetailRow>
                                    <P strong size="large" noDefaultMargin>
                                        SLA Timer
                                    </P>
                                    {prospect ? <SLATimer prospect={prospect} /> : null}
                                    <Button
                                        disabled={!isProspectActive}
                                        size="small"
                                        onClick={() => setShowPostponeSLA(true)}
                                    >
                                        Postpone SLA
                                    </Button>
                                </DetailRow>
                                <DetailRow>
                                    <P strong size="large" noDefaultMargin>
                                        Communication
                                    </P>
                                    <Button
                                        disabled={!isProspectActive}
                                        size="small"
                                        onClick={() => setShowSendCommunication(true)}
                                    >
                                        Send communication
                                    </Button>
                                </DetailRow>
                                <DetailRow>
                                    <P strong size="large" noDefaultMargin>
                                        Call Disposition
                                    </P>
                                    <Button
                                        disabled={!isProspectActive}
                                        size="small"
                                        onClick={() => setShowCallDisposition(true)}
                                    >
                                        Log Call Disposition
                                    </Button>
                                </DetailRow>
                                <div style={{ marginTop: '2.4rem' }}>
                                    {prospect_id ? (
                                        <AddNoteForm
                                            disabled={!isProspectActive}
                                            prospectId={prospect_id}
                                        />
                                    ) : null}
                                </div>
                            </div>

                            <div
                                style={{
                                    flexBasis: '50%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '2rem'
                                }}
                            >
                                <div style={{ display: 'flex', gap: '4rem', alignItems: 'center' }}>
                                    <button
                                        style={{
                                            cursor: 'pointer',
                                            border: 'none',
                                            background: 'none',
                                            padding: '1rem',
                                            fontSize: '1.6rem',
                                            borderBottom: `2px solid ${log === 'prospect' ? '#f26c3a' : '#e0e0e0'}`
                                        }}
                                        onClick={() => setLog('prospect')}
                                    >
                                        Prospect Logs
                                    </button>
                                    <button
                                        style={{
                                            cursor: 'pointer',
                                            border: 'none',
                                            background: 'none',
                                            padding: '1rem',
                                            fontSize: '1.6rem',
                                            borderBottom: `2px solid ${log === 'contact' ? '#f26c3a' : '#e0e0e0'}`
                                        }}
                                        onClick={() => setLog('contact')}
                                    >
                                        Contact Logs
                                    </button>
                                </div>
                                <div
                                    style={{
                                        border: '1px solid #e0e0e0',
                                        padding: '2.4rem',
                                        borderRadius: '12px'
                                    }}
                                >
                                    {logs.length ? (
                                        <LogList logs={logs} />
                                    ) : (
                                        <div>No Logs for Prospect</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </Section>
            {prospect_id ? (
                <>
                    <DeactivateModal
                        externalControls={[showDeactivate, setShowDeactivate]}
                        prospectId={prospect_id}
                    />
                    <PostponeSLATimerModal
                        externalControls={[showPostponeSLA, setShowPostponeSLA]}
                        prospectId={prospect_id}
                    />
                    <SendCommunicationModal
                        externalControls={[showSendCommunication, setShowSendCommunication]}
                        prospectId={prospect_id}
                        communication={communication}
                    />
                    <CallDispositionModal
                        externalControls={[showCallDisposition, setShowCallDisposition]}
                        prospectId={prospect_id}
                    />
                </>
            ) : null}
        </DefaultLayout>
    );
};

export default CRMUsersDetails;
