import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { crmProspectDetails } from './queryKeys';
import envVars from '../../../constants/envVars';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
const useGetCRMProspectDetails = (props, enabled) => {
    const additional = useQueryRequestParams();
    return useQuery({
        queryFn: () => axios.get(`${envVars.API_COMMERCE_HOSTNAME}crm/backoffice/prospect`, {
            ...additional,
            params: { ...props }
        }),
        queryKey: [crmProspectDetails, props.prospect_id],
        enabled
        // refetchInterval: 60000 // 1 minutes in ms
    });
};
export default useGetCRMProspectDetails;
