import { AxiosError } from 'axios';
import React from 'react';
import InlineError from '../InlineError';
import { UiComponentProps } from '@hero/ui-kit/types';
import beErrors, { generalErrorMessage } from '@hero/redux-data/utils/beErrorMessages';

type QueryInlineErrorProps = {
    error?: AxiosError | null;
    centered?: boolean;
} & UiComponentProps;

const extendedBeErrors: Record<string, string> = {
    ...beErrors,
    member_already_cancelled: 'Member already cancelled.',
    no_subscription_found: 'Could not find subscription.',
    member_device_not_found: 'Member device not found.',
    subscription_activation_error: 'Subscription activation error',
    invalid_operation: 'Set plan unavailable',
    wrong_device_model: 'Only supports H200 devices and above',
    search_error: 'Search failed',
    reconext_api_error: 'Reconext API error',
    device_not_connected: 'Device not connected',
    requested_already: 'Requested already',
    device_not_found: 'Device not found',
    not_supported: 'Not supported',
    error_getting_crm_agent: 'CRM agent not found',
    wrong_prospect_id: 'Prospect not found',
    prospect_locked_by_another_agent: 'Prospect locked by another agent',
    cannot_release_lock: 'Cannot release lock',
    prospect_inactive: 'Prospect is inactive',
    communication_not_defined: 'Communication type and/or channel not defined',
    communication_not_allowed: 'Communication type and/or channel not allowed',
    wrong_action: 'Wrong action for the prospect',
    wrong_sla_date: 'Wrong SLA date',
    wrong_note: 'Wrong note'
};

export const getAxiosError = (error?: AxiosError | null) => {
    const preparedMessage =
        error?.message && extendedBeErrors[error.message]
            ? extendedBeErrors[error.message]
            : error?.message || generalErrorMessage;

    if (error?.response?.data) {
        if (Array.isArray(error?.response?.data) && error?.response?.data.length) {
            return extendedBeErrors[error?.response?.data[0]];
        }

        const errorData = error.response.data as { error?: string | string[] };

        if (typeof errorData === 'object' && 'error' in errorData) {
            if (Array.isArray(errorData.error)) {
                return errorData.error
                    .map((error) => {
                        if (extendedBeErrors[error]) {
                            return extendedBeErrors[error];
                        }
                        return error;
                    })
                    .join(',');
            }

            if (typeof errorData.error === 'string') {
                return extendedBeErrors[errorData.error] || preparedMessage;
            }

            return preparedMessage;
        }

        const data = error.response.data as { errors?: [] | string; message?: string };

        if (typeof data === 'object' && 'errors' in data) {
            if (Array.isArray(data.errors)) {
                return data.errors
                    .map((error) => {
                        if (extendedBeErrors[error]) {
                            return extendedBeErrors[error];
                        }
                        return error;
                    })
                    .join(',');
            }

            if (typeof data.errors === 'string') {
                return extendedBeErrors[data.errors] || preparedMessage;
            }

            return preparedMessage;
        }
        if (typeof data === 'object' && 'message' in data) {
            const message = typeof data.message === 'string' ? data.message : generalErrorMessage;
            return message && extendedBeErrors[message]
                ? extendedBeErrors[message]
                : message || generalErrorMessage;
        }
        return preparedMessage;
    }
    return preparedMessage;
};

const QueryInlineError: React.FC<QueryInlineErrorProps> = ({ error, centered = false, styles }) => {
    const errorMessage = React.useMemo(() => {
        return getAxiosError(error);
    }, [error]);

    return <InlineError message={errorMessage} centered={centered} styles={styles} />;
};

export default QueryInlineError;
