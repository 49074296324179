import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import * as React from 'react';
import { useReactivationFlowContext } from './context/ReactivationFlowProvider';
import UnpaidInvoices from './UnpaidInvoices';

const OtherPlanDetails: React.FC = () => {
    const { planDetails, invoices } = useReactivationFlowContext();

    const marginTop = useComponentMargin({ marginTop: 'regular' });

    const margin = {
        marginBottom: '1.4rem',
        marginTop: '1.4rem',
        borderWidth: '0.2rem',
        borderColor: 'whitesmoke'
    };

    return (
        <Section noDefaultPadding styles={{ textAlign: 'left', ...marginTop }} width="full">
            <Container gridTemplateColumns="1fr 1fr">
                <div>
                    <P size="large" noDefaultMargin>
                        PLAN CANCELLED DATE
                    </P>
                    <P size="large" strong noDefaultMargin>
                        {planDetails.planCancelledDate}
                    </P>
                </div>
                <div>
                    <P size="large" noDefaultMargin>
                        PLAN REACTIVATION DATE
                    </P>
                    <P size="large" strong noDefaultMargin>
                        {planDetails.planReactivationDate}
                    </P>
                </div>
            </Container>
            <hr style={{ ...margin }} />
            <UnpaidInvoices invoices={invoices} />
            <hr style={{ ...margin }} />
            <Container gridTemplateColumns="1fr 1fr">
                <div>
                    <P size="large" noDefaultMargin>
                        CANCELLATION FEE PAID
                    </P>
                    <P size="large" strong noDefaultMargin>
                        {planDetails.cancellationFeeAmountPaid}
                    </P>
                </div>
                <div>
                    <P size="large" noDefaultMargin>
                        CANCELLATION FEE PAID (in months)
                    </P>
                    <P size="large" strong noDefaultMargin>
                        {planDetails.cancellationFeeMonthsPaid} Months
                    </P>
                </div>
            </Container>

            <P noDefaultMargin size="small">
                *CANCELLATION FEE PAID is used to cover unpaid invoices, or transferred to future
                prepaid months
            </P>

            <hr style={{ ...margin }} />
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <P noDefaultMargin>UNPAID INVOICES COVERED WITH CANCELLATION FEE</P>
                <P noDefaultMargin strong>
                    {planDetails.cancellationFeeCoveringDebtMonths} Months
                </P>
            </div>
            <hr style={{ borderColor: '#fff' }} />
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <P noDefaultMargin>CANCELLATION FEE TRANSFERRED TO PREPAID PERIOD</P>
                <P noDefaultMargin strong>
                    {planDetails.cancellationFeeMonthsForPrepaid} Months
                </P>
            </div>

            <hr style={{ ...margin }} />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <P noDefaultMargin>TOTAL AMOUNT OWED</P>
                <P noDefaultMargin strong>
                    {planDetails.amountOwed}
                </P>
            </div>
            <hr style={{ ...margin }} />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <P noDefaultMargin>NEW SUBSCRIPTION CHARGE</P>
                <P noDefaultMargin strong>
                    {planDetails.reactivationPlanPriceWithTax} (*tax included)
                </P>
            </div>
            <hr style={{ ...margin }} />
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <P noDefaultMargin strong>
                    PREPAID END DATE
                </P>
                <P noDefaultMargin strong>
                    {planDetails.prepaidEndDate}
                </P>
            </div>
            {planDetails.prepaidEndDate !== 'N/A' ? (
                <P noDefaultMargin size="small">
                    *Standard Monthly subscription fee comes into effect after prepaid period
                    expires
                </P>
            ) : null}

            <hr style={{ ...margin }} />

            <P centered noDefaultMargin size="large">
                <strong>TOTAL CHARGE:</strong> {planDetails.totalAmount} (*tax included)
            </P>
        </Section>
    );
};

export default OtherPlanDetails;
