import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { paymentMethod } from './queryKeys';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
import envVars from '../../../constants/envVars';
const useUpdatePaymentMethod = () => {
    const queryClient = useQueryClient();
    const params = useQueryRequestParams();
    return useMutation({
        mutationFn: (attributes) => axios.post(`${envVars.API_COMMERCE_HOSTNAME}subscription/backoffice/set-payment`, { ...attributes }, { ...params, timeout: 120000 }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [paymentMethod] });
        },
        onError: (error) => {
            return error;
        }
    });
};
export default useUpdatePaymentMethod;
