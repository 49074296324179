import { users, monitoring } from '../constants/pages';
// temporary solution for role based visibility,
// until refactor and until roles are implemented differently on BE
// *DO*:    leave out "roles" property to make a nav item visible to everyone
// *DON'T*: set "roles" to empty array (unless an experimental section needs to be hidden from everyone)
// *NOTE*:  this is just for nav items visibility in the sidebar, pages are still accessible directly
const mainNavigation = [
    {
        ...users,
        roles: ['is_backoffice_admin']
    },
    {
        name: 'Membership',
        to: '/membership',
        roles: ['is_backoffice_admin']
    },
    {
        name: 'Organizations',
        to: '/organizations',
        roles: ['is_backoffice_admin']
    },
    {
        name: 'Devices',
        to: '/devices',
        roles: ['is_backoffice_admin']
    },
    {
        ...monitoring,
        roles: ['is_backoffice_admin']
    },
    {
        name: 'RMA',
        to: '/rma',
        roles: ['is_backoffice_admin']
    },
    {
        name: 'Utilities',
        to: '/utilities',
        roles: ['is_backoffice_admin']
    },
    {
        name: 'Payor A Sign Up',
        to: '/payora-signup',
        roles: ['is_telemarketing_admin']
    },
    {
        name: 'Orders',
        to: '/orders/prospect-signup',
        roles: ['is_backoffice_admin', 'is_telemarketing_admin']
    },
    {
        name: 'Retention',
        to: '/retention',
        roles: ['is_backoffice_admin']
    },
    {
        name: 'CRM',
        to: '/crm',
        roles: ['is_backoffice_admin']
    }
];
export default mainNavigation;
