import React from 'react';
import PayorASignup from '../pages/PayorASignup';
import Home from '../pages/Home';
import ProspectSignup from '../pages/Orders/ProspectSignup';
import MembershipRoutes from '../pages/Membership/MembershipRoutes';
import RmaRoutes from '../pages/Rma/RmaRoutes';
import OrganizationRoutes from '../pages/Organizations/OrganizationRoutes';
import UserRoutes from '../pages/Users/UserRoutes';
import DeviceRoutes from '../pages/Devices/DeviceRoutes';
import MonitoringRoutes from '../pages/Monitoring/MonitoringRoutes';
import envVars from '../constants/envVars';
import useAuthStatus from '@hero/hero-auth/useAuthStatus';
import Loader from '@hero/ui-kit/components/Loader';
import { Routes as RouterRoutes, Route } from 'react-router-dom';
import UtilitiesRoutes from '../pages/UtilitiesPage/UtilitiesRoutes';
import DischargedMembersList from '../pages/Retention/components/DischargedMembersList';
import CRMRoutes from '../pages/CRM/CRMRoutes';

const Routes: React.FC = () => {
    const { accessToken, authStatus, isLoggedIn } = useAuthStatus(envVars);

    const isFetchingToken = !accessToken && authStatus === 'loggedIn';
    const isLoggingOut = accessToken && (isLoggedIn || authStatus === 'loggingOut');

    if (isFetchingToken || isLoggingOut) {
        return <Loader />;
    }

    if (accessToken) {
        return (
            <RouterRoutes>
                <Route path="/" element={<Home />} />
                <Route path="/users/*" element={<UserRoutes />} />
                <Route path="/devices/*" element={<DeviceRoutes />} />
                <Route path="/organizations/*" element={<OrganizationRoutes />} />
                <Route path="/membership/*" element={<MembershipRoutes />} />
                <Route path="/rma/*" element={<RmaRoutes />} />
                <Route path="/monitoring/*" element={<MonitoringRoutes />} />
                <Route path="/utilities/*" element={<UtilitiesRoutes />} />
                <Route path="/orders/prospect-signup" element={<ProspectSignup />} />
                <Route path="/payora-signup" element={<PayorASignup />} />
                <Route path="/retention" element={<DischargedMembersList />} />
                <Route path="/crm/*" element={<CRMRoutes />} />
            </RouterRoutes>
        );
    }

    return null;
};

export default Routes;
