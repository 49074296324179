import * as React from 'react';
import { UserDrip } from '../../api/useGetUserDrips';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import useUpdateUserDrips, { DripType } from '../../api/useUpdateUserDrips';

type DripTypeName =
    | 'RMA_DRIP'
    | 'PAYMENT_FAILED_DRIP'
    | 'ONBOARDING_DRIP'
    | 'RETENTION_DRIP'
    | 'RE_ENGAGEMENT_DRIP'
    | 'RTM_DRIP';

const dripRecords: Record<DripTypeName, DripType> = {
    RMA_DRIP: 1,
    PAYMENT_FAILED_DRIP: 2,
    ONBOARDING_DRIP: 3,
    RETENTION_DRIP: 4,
    RE_ENGAGEMENT_DRIP: 5,
    RTM_DRIP: 6
};

interface UserDripChangeProps {
    drips?: UserDrip[];
    email: string;
    onSuccess: () => void;
    dripType: DripTypeName;
    dripLabel: string;
}

const UserDripChange: React.FC<UserDripChangeProps> = ({
    drips = [],
    email,
    onSuccess,
    dripType,
    dripLabel
}) => {
    const { mutateAsync: updateUserDrip, isPending: isUpdateUserDripPending } =
        useUpdateUserDrips();

    const rmaDripData = React.useMemo(() => {
        const rmaDrip = drips.find((item) => item.drip_type === dripType && item.email === email);

        if (rmaDrip) {
            return {
                isRmaDripUnsubscribed: rmaDrip.unsubscribed
            };
        }

        return {
            isRmaDripUnsubscribed: false
        };
    }, [email, drips, dripType]);

    const handleToggleRmaDrip = () => {
        const drip = dripRecords[dripType];

        if (email) {
            updateUserDrip({
                email,
                drip,
                unsubscribe: !rmaDripData.isRmaDripUnsubscribed
            }).then(onSuccess);
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between'
            }}
        >
            <P noDefaultMargin>{dripLabel}</P>
            <div>
                <Button
                    size="small"
                    variant={!rmaDripData.isRmaDripUnsubscribed ? 'primary' : 'outline'}
                    disabled={isUpdateUserDripPending}
                    noDefaultMargin
                    onClick={handleToggleRmaDrip}
                    styles={{
                        marginRight: '1.2rem',
                        ...(!rmaDripData.isRmaDripUnsubscribed && {
                            pointerEvents: 'none'
                        })
                    }}
                >
                    Yes
                </Button>
                <Button
                    size="small"
                    variant={rmaDripData.isRmaDripUnsubscribed ? 'primary' : 'outline'}
                    disabled={isUpdateUserDripPending}
                    noDefaultMargin
                    onClick={handleToggleRmaDrip}
                    styles={{
                        ...(rmaDripData.isRmaDripUnsubscribed && {
                            pointerEvents: 'none'
                        })
                    }}
                >
                    No
                </Button>
            </div>
        </div>
    );
};

export default UserDripChange;
