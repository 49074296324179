import React, { FC, useCallback, useMemo } from 'react';
import Form from '@hero/ui-kit/inputs/Form';
import {
    SetRmaRequestPickUpParams,
    setRmaRequestPickUpSchema
} from '@hero/validators/forms/backoffice/rmaRequestSchema';
import InlineLoader from '../../../components/InlineLoader';
import ReturnShippingFormWrapper from '../../forms/shipping/ReturnShippingFormWrapper';
import useCustomerContactDetails from '../../utils/useCustomerContactDetails';
import { useExchangeContext } from '../context/ExchangeProvider';
import QueryInlineError from '../../../components/QueryInlineError';
import ProviderApiError from '../../../components/ProviderApiError';

interface OldDeviceProps {
    closeExchangeModal: () => void;
}

export const OldDevice: FC<OldDeviceProps> = ({ closeExchangeModal }) => {
    const {
        onCompleteRma,
        isSetRmaPickupLoading,
        isOrganizationMember,
        rmaEnums,
        setRmaPickupError,
        membership,
        enterpriseEnrollment,
        rmaId
    } = useExchangeContext();

    const handleCompleteRma = useCallback(
        (formValues: SetRmaRequestPickUpParams) => {
            onCompleteRma(formValues);
        },
        [onCompleteRma]
    );

    const contactDetails = useCustomerContactDetails(
        isOrganizationMember,
        membership,
        enterpriseEnrollment
    );

    const defaultValues: Partial<SetRmaRequestPickUpParams> = useMemo(
        () => ({
            return_shipping_option: '1',
            ...contactDetails
        }),
        [contactDetails]
    );

    return isSetRmaPickupLoading ? (
        <InlineLoader />
    ) : (
        <Form
            validationSchema={setRmaRequestPickUpSchema}
            submitFn={handleCompleteRma}
            defaultValues={defaultValues}
        >
            <ReturnShippingFormWrapper
                rmaId={rmaId}
                onClose={closeExchangeModal}
                buttonLabel={'Complete Exchange'}
                state={contactDetails?.state}
                rmaEnums={rmaEnums}
            />
            {setRmaPickupError ? <QueryInlineError centered error={setRmaPickupError} /> : null}
            {setRmaPickupError ? <ProviderApiError centered error={setRmaPickupError} /> : null}
        </Form>
    );
};
