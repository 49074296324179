import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import {
    BackofficeMembershipDetails,
    CancellationDetails,
    MembershipReactivationDetails,
    PaymentUpdateParams
} from '@hero/hero-types';
import formatDollarAmount from '@hero/hero-utils/currency';
import { formatDate } from '@hero/hero-utils/date';
import {
    getMembershipReactivationDetails,
    membershipReactivationDetailsReset
} from '@hero/redux-data/backoffice/membershipReactivationDetails/actionCreators';
import {
    isMembershipReactivationDetailsLoadingSelector,
    membershipReactivationDetailsSelector,
    membershipReactivationDetailsErrorSelector
} from '@hero/redux-data/backoffice/membershipReactivationDetails/selectors';
import {
    membershipReactivationRefundChargeReset,
    reactivateRefundChargeMembership
} from '@hero/redux-data/backoffice/membershipReactivationRefundCharge/actionCreators';
import {
    isMembershipReactivationRefundChargeLoadingSelector,
    membershipReactivationRefundChargeErrorSelector,
    isMembershipReactivationRefundChargeSuccess
} from '@hero/redux-data/backoffice/membershipReactivationRefundCharge/selectors';
import {
    membershipReactivationReset,
    reactivateMembership
} from '@hero/redux-data/backoffice/membershipReactivation/actionCreators';
import {
    isMembershipReactivationLoadingSelector,
    membershipReactivationErrorSelector,
    membershipReactivationNewIdSelector
} from '@hero/redux-data/backoffice/membershipReactivation/selectors';
import { useNavigate } from 'react-router-dom';
import { PlanPickerParams } from '../schema';
import useGetReactivationPlanEligibility from '../../../api/useGetReactivationPlanEligibility';
import { usedPlans } from '../../PlanPicker';
import useUpdatePaymentMethod from '../../../api/useUpdatePaymentMethod';
import beErrors from '@hero/redux-data/utils/beErrorMessages';

type ReactivationSteps =
    | 'ConfirmCard'
    | 'Details'
    | 'FOP'
    | 'ChangePlan'
    | 'Reactivate'
    | 'Success'
    | 'Fail';

type ReactivationFlowContextType = {
    onCloseReactivationFlow: () => void;
    goToFOP: () => void;
    goToChangePlan: () => void;
    onConfirmCard: () => void;
    onCreditCardCancel: () => void;
    detailsBtnConfirm: () => void;
    onCreditCardUpdate: (formValues: PaymentUpdateParams) => void;
    reactivationStep: ReactivationSteps;
    isLoading: boolean;
    onReactivate: () => void;
    selectPlan: (attributes: PlanPickerParams) => void;
    onRefundCharge: () => void;
    goToDetails: () => void;
    updateCardErrorMessage: string;
    obligationEndDate?: string;
    planDetails: {
        prepaidEndDate: string;
        obligationEndDate: string;
        planActiveDate: string;
        planCancelledDate: string;
        planReactivationDate: string;
        amountOwed: string;
        initiationFeePayed: string;
        cancellationFeeMonthsForPrepaid: string;
        cancellationFeeMonthsPaid: number;
        cancellationFeeCoveringDebtMonths: number;
        reactivationPlans: Array<{ name: string; storefront_key: string; price: number }>;
        isEligibleForPlanSelection: boolean;
        reactivationPlanPrice: string;
        reactivationPlanTax: string;
        reactivationPlanPriceWithTax: string;
        totalAmount: string;
        cancellationPrepaidEndDate: string;
        cancellationFeeAmountPaid: string;
    };
    cardDetails: {
        last4digits: string;
        expirationYear: string;
        expirationMonth: string;
        name: string;
        cardType: string;
    };
    successDetails: {
        list: Array<string>;
    };
    failDetails: {
        header: string;
        message: string;
    };
    detailsBtnLabel: string;
    storeFrontKey: string;
    isReactivationRefundChargeError: boolean;
    invoices: MembershipReactivationDetails['unpaid_preview_invoices'];
    isEligibleForPlanSelection: boolean;
    showBundleScreen: boolean;
    selectedPlanName: string;
};

// Mock data
// const mockMembershipReactivationDetails: MembershipReactivationDetails = {
//     action: 'Reactivate',
//     amount_owed: 50.0,
//     unpaid_preview_invoices: [
//         {
//             invoice_number: 'INV-001',
//             invoice_status: 'Unpaid',
//             amount: 25.0,
//             invoice_date_start: '2023-09-01',
//             invoice_date_end: '2023-09-30'
//         },
//         {
//             invoice_number: 'INV-002',
//             invoice_status: 'Unpaid',
//             amount: 25.0,
//             invoice_date_start: '2023-10-01',
//             invoice_date_end: '2023-10-31'
//         }
//     ],
//     initiation_fee_payed: 100.0,
//     obligation_end_date: '2024-09-01',
//     prepaid_end_date: '2024-12-31',
//     cancellation_fee_months_for_prepaid: 3
// };

const ReactivationFlowContext = React.createContext<ReactivationFlowContextType | undefined>(
    undefined
);

export const useReactivationFlowContext = () => {
    const ctx = React.useContext(ReactivationFlowContext);

    if (ctx === undefined) {
        throw new Error(
            `'useReactivationFlowContext' must be used within a 'ReactivationFlowContextProvider'`
        );
    }

    return ctx;
};

const getLabel = (label: string | number | undefined | null): string => (label ? `${label}` : '-');

type IReactivationFlowProvider = {
    membershipId: number;
    children: React.ReactNode;
    onCompleteFlow?: () => void;
    cancellationDetails?: CancellationDetails;
    membership: BackofficeMembershipDetails;
    fetchMembershipDetails: () => void;
    isMembershipDetailsRefetching: boolean;
} & Pick<ModalProps, 'externalControls'>;

const ReactivationFlowProvider = ({
    membershipId,
    children,
    externalControls,
    onCompleteFlow,
    cancellationDetails,
    membership,
    isMembershipDetailsRefetching,
    fetchMembershipDetails
}: IReactivationFlowProvider) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [, setExternalState] = externalControls ?? [];

    const [reactivationStep, setReactivationStep] =
        React.useState<ReactivationSteps>('ConfirmCard');

    const [selectedPlan, setSelectedPlan] = React.useState<string | null>(null);

    // @ts-ignore
    const selectedPlanName: string = selectedPlan ? usedPlans[selectedPlan] : undefined;

    // data
    const membershipReactivationDetails = useSelector(
        membershipReactivationDetailsSelector,
        shallowEqual
    );

    const isReactivationRefundChargeSuccess = useSelector(
        isMembershipReactivationRefundChargeSuccess,
        shallowEqual
    );
    const membershipReactivationNewId = useSelector(
        membershipReactivationNewIdSelector,
        shallowEqual
    );

    // loading

    const isMembershipReactivationDetailsLoading = useSelector(
        isMembershipReactivationDetailsLoadingSelector,
        shallowEqual
    );
    const isMembershipReactivationRefundChargeLoading = useSelector(
        isMembershipReactivationRefundChargeLoadingSelector,
        shallowEqual
    );
    const isMembershipReactivationLoading = useSelector(
        isMembershipReactivationLoadingSelector,
        shallowEqual
    );

    // errors
    const { error: isReactivationDetailsError, errorMessage: reactivationDetailsErrorMessage } =
        useSelector(membershipReactivationDetailsErrorSelector, shallowEqual);
    const {
        error: isReactivationRefundChargeError,
        errorMessage: reactivationRefundChargeErrorMessage
    } = useSelector(membershipReactivationRefundChargeErrorSelector, shallowEqual);
    const { error: isReactivationError, errorMessage: reactivationErrorMessage } = useSelector(
        membershipReactivationErrorSelector,
        shallowEqual
    );

    const {
        data: planEligibility,
        isFetching: isPlanEligibilityLoading,
        isError: isPlanEligibilityError,
        error: planEligibilityError
    } = useGetReactivationPlanEligibility(membershipId);

    const {
        mutateAsync: updatePaymentMethod,
        isPending: isUpdatingPaymentMethod,
        isError: isCreditCardError,
        error: creditCardErrorMessage
    } = useUpdatePaymentMethod();

    const isLoading =
        isMembershipDetailsRefetching ||
        isUpdatingPaymentMethod ||
        isMembershipReactivationDetailsLoading ||
        isMembershipReactivationRefundChargeLoading ||
        isMembershipReactivationLoading ||
        isPlanEligibilityLoading;

    const isError =
        isReactivationDetailsError ||
        isReactivationRefundChargeError ||
        isReactivationError ||
        isPlanEligibilityError;

    React.useEffect(() => {
        dispatch(getMembershipReactivationDetails({ id: membershipId }));
    }, [membershipId, dispatch]);

    React.useEffect(() => {
        if (selectedPlan) {
            dispatch(
                getMembershipReactivationDetails({
                    id: membershipId,
                    reactivation_plan_storefront_key: selectedPlan
                })
            );
        }
    }, [membershipId, dispatch, selectedPlan]);

    React.useEffect(() => {
        return () => {
            dispatch(membershipReactivationDetailsReset());
            dispatch(membershipReactivationRefundChargeReset());
            dispatch(membershipReactivationReset());
        };
    }, [dispatch]);

    React.useEffect(() => {
        isReactivationRefundChargeSuccess && setReactivationStep('Reactivate');
    }, [isReactivationRefundChargeSuccess]);

    // const isBundlePlan = (plan: string) => {
    //     return ['new-bundle-1-year', 'new-bundle-2-year', 'new-bundle-3-year'].includes(plan);
    // };

    // const isBundlePlanWithActivePrepaidPeriod = React.useMemo(() => {
    //     const isPrepaidPeriodInFuture = membership.prepaid_end_date
    //         ? new Date(membership.prepaid_end_date) > new Date()
    //         : false;

    //     const CancellationFeeMonths =
    //         membershipReactivationDetails?.cancellation_fee_months_for_prepaid || 0;

    //     return isPrepaidPeriodInFuture || CancellationFeeMonths > 0;
    // }, [membership, membershipReactivationDetails]);

    const isEligibleForPlanSelection = React.useMemo(() => {
        return planEligibility?.data?.data?.is_eligible_for_plan_selection || false;
    }, [planEligibility]);

    const showBundleScreen =
        membershipReactivationDetails?.cancellation_fee_months_for_prepaid === 0;

    React.useEffect(() => {
        isError && setReactivationStep('Fail');
    }, [isError]);

    React.useEffect(() => {
        membershipReactivationNewId && setReactivationStep('Success');
    }, [membershipReactivationNewId]);

    const onCloseReactivationFlow = React.useCallback(() => {
        if (membershipReactivationNewId) {
            navigate(`/membership/${membershipReactivationNewId}/details`, { replace: true });
        }
        setReactivationStep('ConfirmCard');
        setExternalState && setExternalState(false);
        onCompleteFlow && onCompleteFlow();
    }, [onCompleteFlow, setExternalState, membershipReactivationNewId, navigate]);

    const goToFOP = React.useCallback(() => {
        setReactivationStep('FOP');
    }, []);

    const onReactivate = React.useCallback(() => {
        dispatch(
            reactivateMembership({
                id: membershipId,
                ...(selectedPlan && { reactivation_plan_storefront_key: selectedPlan })
            })
        );
    }, [dispatch, membershipId, selectedPlan]);

    const selectPlan = React.useCallback(
        ({ plan }: PlanPickerParams) => {
            setSelectedPlan(plan);
            setReactivationStep('Details');
        },
        [dispatch, membershipId]
    );

    const goToDetails = React.useCallback(() => {
        setReactivationStep('Details');
    }, []);

    const goToChangePlan = React.useCallback(() => {
        setReactivationStep('ChangePlan');
    }, []);

    const onRefundCharge = React.useCallback(() => {
        dispatch(reactivateRefundChargeMembership({ id: membershipId }));
    }, [dispatch, membershipId]);

    const onConfirmCard = React.useCallback(() => {
        if (isEligibleForPlanSelection) {
            setReactivationStep('ChangePlan');
        } else {
            setReactivationStep('Details');
        }
    }, [isEligibleForPlanSelection]);

    const onCreditCardUpdate = React.useCallback(
        (formValues: PaymentUpdateParams) => {
            if (membershipId) {
                updatePaymentMethod({ id: membershipId, ...formValues, attempt_pay: false })
                    .then(() => {
                        setReactivationStep('ConfirmCard');
                        fetchMembershipDetails();
                    })
                    .catch(() => {});
            }
        },
        [dispatch, membershipId]
    );

    const onCreditCardCancel = React.useCallback(() => {
        setReactivationStep('ConfirmCard');
    }, []);

    const planDetails = React.useMemo(() => {
        return {
            prepaidEndDate: membershipReactivationDetails?.preview_prepaid_end_date
                ? formatDate(membershipReactivationDetails.preview_prepaid_end_date)
                : 'N/A',
            obligationEndDate: membershipReactivationDetails?.obligation_end_date
                ? formatDate(membershipReactivationDetails.obligation_end_date)
                : 'N/A',
            planActiveDate: membership?.activated_at ? formatDate(membership.activated_at) : '-',
            planCancelledDate: cancellationDetails?.subscription?.cancelled_at
                ? formatDate(cancellationDetails.subscription.cancelled_at)
                : '-',
            planReactivationDate: formatDate(new Date()),
            amountOwed: formatDollarAmount(membershipReactivationDetails?.amount_owed || 0, true),
            initiationFeePayed: formatDollarAmount(
                membershipReactivationDetails?.initiation_fee_payed || 0,
                true
            ),
            cancellationFeeMonthsForPrepaid: `${membershipReactivationDetails?.cancellation_fee_months_for_prepaid || 0}`,
            cancellationFeeMonthsPaid:
                membershipReactivationDetails?.cancellation_fee_months_paid || 0,
            cancellationFeeCoveringDebtMonths:
                membershipReactivationDetails?.cancellation_fee_covering_debt_months || 0,
            reactivationPlans: planEligibility?.data?.data?.reactivation_plans || [],
            isEligibleForPlanSelection:
                planEligibility?.data?.data?.is_eligible_for_plan_selection || false,
            reactivationPlanPrice: formatDollarAmount(
                membershipReactivationDetails?.reactivation_plan_price || 0,
                true
            ),
            reactivationPlanTax: formatDollarAmount(
                membershipReactivationDetails?.reactivation_plan_tax || 0,
                true
            ),
            reactivationPlanPriceWithTax: formatDollarAmount(
                membershipReactivationDetails?.reactivation_plan_price_with_tax || 0,
                true
            ),
            totalAmount: formatDollarAmount(membershipReactivationDetails?.total_amount || 0, true),
            cancellationPrepaidEndDate: membershipReactivationDetails?.cancellation_prepaid_end_date
                ? formatDate(membershipReactivationDetails?.cancellation_prepaid_end_date)
                : '-',
            cancellationFeeAmountPaid: formatDollarAmount(
                membershipReactivationDetails?.cancellation_fee_amount_paid || 0,
                true
            )
        };
    }, [membership, cancellationDetails, membershipReactivationDetails, planEligibility]);

    const cardDetails = React.useMemo(() => {
        return {
            last4digits: getLabel(membership?.payment_card?.last4),
            expirationYear: getLabel(membership?.payment_card?.expire_year),
            expirationMonth: getLabel(membership?.payment_card?.expire_month),
            name: getLabel(membership?.payment_card?.name_on_card),
            cardType: getLabel(membership?.payment_card?.type)
        };
    }, [membership]);

    const successDetails = React.useMemo(() => {
        const firstItem = !isEligibleForPlanSelection
            ? `Prepaid period until: ${planDetails.prepaidEndDate}`
            : `Amount charged: ${planDetails.totalAmount}`;

        return {
            list: [firstItem, `RMA was voided`, `Confirmation email was sent`]
        };
    }, [planDetails]);

    const failDetails = React.useMemo(() => {
        const planEligibilityErrors = planEligibilityError?.response?.data?.errors || [];

        const planEligibilityErrorMessage = Array.isArray(planEligibilityErrors)
            ? planEligibilityErrors.join(', ')
            : planEligibilityErrors;

        return {
            header: 'Something went wrong',
            message:
                reactivationRefundChargeErrorMessage ||
                reactivationDetailsErrorMessage ||
                reactivationErrorMessage ||
                planEligibilityErrorMessage ||
                'Reactivation failed'
        };
    }, [
        reactivationDetailsErrorMessage,
        reactivationRefundChargeErrorMessage,
        reactivationErrorMessage,
        planEligibilityError
    ]);

    const updateCardErrorMessage = React.useMemo<string>(() => {
        const cardError: string =
            // @ts-ignore
            creditCardErrorMessage?.response?.data?.message || 'card_declined';
        return isCreditCardError ? beErrors[cardError] || beErrors['card_declined'] : '';
    }, [creditCardErrorMessage, isCreditCardError]);

    const detailsBtnConfirm = React.useCallback(() => {
        if (membershipReactivationDetails?.action !== 'no_action') {
            dispatch(reactivateRefundChargeMembership({ id: membershipId }));
        } else {
            setReactivationStep('Reactivate');
        }
    }, [dispatch, membershipId, membershipReactivationDetails]);

    const detailsBtnLabel = 'Reactivate Membership';

    return (
        <ReactivationFlowContext.Provider
            value={{
                selectedPlanName,
                isEligibleForPlanSelection,
                showBundleScreen,
                isLoading,
                reactivationStep,
                onCloseReactivationFlow,
                onCreditCardUpdate,
                onCreditCardCancel,
                goToFOP,
                selectPlan,
                onReactivate,
                onRefundCharge,
                onConfirmCard,
                goToDetails,
                goToChangePlan,
                planDetails,
                cardDetails,
                successDetails,
                failDetails,
                updateCardErrorMessage,
                detailsBtnLabel,
                detailsBtnConfirm,
                obligationEndDate: membership?.obligation_end_date,
                storeFrontKey: membership?.current_plan?.storefront_key || '-',
                isReactivationRefundChargeError,
                invoices: membershipReactivationDetails?.unpaid_preview_invoices || []
            }}
        >
            {children}
        </ReactivationFlowContext.Provider>
    );
};

export default ReactivationFlowProvider;
