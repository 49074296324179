import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { crmProspectDetails } from './queryKeys';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
import envVars from '../../../constants/envVars';
const useProspectAction = () => {
    const queryClient = useQueryClient();
    const params = useQueryRequestParams();
    return useMutation({
        mutationFn: (attributes) => axios.patch(`${envVars.API_COMMERCE_HOSTNAME}crm/backoffice/prospect`, { ...attributes }, { ...params, params: { prospect_id: attributes.prospect_id } }),
        onSuccess: (_, attr) => {
            attr.prospect_id &&
                attr.action !== 'refresh_lock' &&
                queryClient.invalidateQueries({ queryKey: [crmProspectDetails, attr.prospect_id] });
        },
        onError: (error) => {
            return error;
        }
    });
};
export default useProspectAction;
