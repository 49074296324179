import * as React from 'react';
import { useWatch } from '@hero/ui-kit/inputs/Form';
import ReturnShippingFormFields from './ReturnShippingFormFields';
import { RmaEnums } from '@hero/hero-types';

type Props = {
    buttonLabel?: string;
    state?: string;
    rmaEnums?: RmaEnums;
    rmaId?: number;
    onClose?: () => void;
};
const ReturnShippingFormWrapper: React.FC<Props> = ({ buttonLabel, state, rmaEnums, rmaId, onClose }) => {
    const isManual = useWatch({ name: 'is_manual_return_shipping' });
    return (
        <ReturnShippingFormFields
            rmaEnums={rmaEnums}
            isManual={!!isManual}
            buttonLabel={buttonLabel}
            state={state}
            rmaId={rmaId}
            onClose={onClose}
        />
    );
};

export default ReturnShippingFormWrapper;
