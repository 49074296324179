import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import { useReactivationFlowContext } from '../context/ReactivationFlowProvider';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import H from '@hero/ui-kit/typography/H';

export const Reactivate: React.FC = () => {
    const { onReactivate, isEligibleForPlanSelection, planDetails, selectedPlanName, goToDetails } =
        useReactivationFlowContext();
    const marginTop = useComponentMargin({ marginTop: 'large' });

    return (
        <>
            <Section
                noDefaultPadding
                styles={{ textAlign: 'left', ...marginTop }}
                width="full"
                centered
            >
                <H role="h5" styles={{ marginBottom: '2rem', fontSize: '2.6rem' }}>
                    SUMMARY
                </H>
                {!isEligibleForPlanSelection ? (
                    <>
                        <P size="large" noDefaultMargin>
                            Prepaid period until: <strong>{planDetails.prepaidEndDate}</strong>
                        </P>
                    </>
                ) : (
                    <>
                        <ul>
                            <li>
                                <P size="large">
                                    Prepaid period until:{' '}
                                    <strong>{planDetails.prepaidEndDate}</strong>
                                </P>
                            </li>
                            <li>
                                <P size="large">
                                    Membership plan: <strong>{selectedPlanName}</strong>
                                </P>
                            </li>
                            <li>
                                <P size="large" noDefaultMargin>
                                    Total reactivation charge:{' '}
                                    <strong>{planDetails.totalAmount}</strong>
                                </P>
                            </li>
                        </ul>
                    </>
                )}
            </Section>
            <Section centered>
                <Button width="large" onClick={onReactivate} noDefaultMargin>
                    Reactivate Membership
                </Button>
                <Button
                    onClick={goToDetails}
                    variant="secondary"
                    size="regular"
                    styles={marginTop}
                    noDefaultMargin
                >
                    Back
                </Button>
            </Section>
        </>
    );
};
