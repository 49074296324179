import React, { useMemo } from 'react';

import Form from '@hero/ui-kit/inputs/Form';
import {
    setRmaRequestPickUpSchema,
    SetRmaRequestPickUpParams
} from '@hero/validators/forms/backoffice/rmaRequestSchema';

import InlineLoader from '../../components/InlineLoader';
import { useCancellationStateMachine } from './context/cancellationStateMachine';
import ReturnShippingFormWrapper from '../forms/shipping/ReturnShippingFormWrapper';
import useCustomerContactDetails from '../utils/useCustomerContactDetails';
import QueryInlineError from '../../components/QueryInlineError';
import ProviderApiError from '../../components/ProviderApiError';

const StepShipping: React.FC<{ onClose?: () => void }> = ({ onClose }) => {
    const {
        isOrganizationMember,
        handleCompleteRma,
        enrollment,
        membership,
        rmaEnums,
        createRmaDraftError,
        isCreateRmaDraftLoading,
        isSetRmaPickupLoading,
        setRmaPickupError,
        rmaRequest
    } = useCancellationStateMachine();

    const contactDetails = useCustomerContactDetails(isOrganizationMember, membership, enrollment);

    const isLoading = isCreateRmaDraftLoading || isSetRmaPickupLoading;

    const defaultValues: Partial<SetRmaRequestPickUpParams> = useMemo(
        () => ({
            return_shipping_option: '1',
            ...contactDetails
        }),
        [contactDetails]
    );

    return isLoading ? (
        <InlineLoader />
    ) : (
        <Form
            validationSchema={setRmaRequestPickUpSchema}
            defaultValues={defaultValues}
            submitFn={handleCompleteRma}
            validationMode="onTouched"
        >
            <ReturnShippingFormWrapper
                rmaId={rmaRequest?.id}
                state={contactDetails?.state}
                rmaEnums={rmaEnums}
                onClose={onClose}
            />
            {createRmaDraftError ? <QueryInlineError centered error={createRmaDraftError} /> : null}
            {setRmaPickupError ? <QueryInlineError centered error={setRmaPickupError} /> : null}
            {setRmaPickupError ? <ProviderApiError centered error={setRmaPickupError} /> : null}
        </Form>
    );
};

export default StepShipping;
