import * as React from 'react';
import { CRMProspectLog } from '../../types';
import P from '@hero/ui-kit/typography/P';
import { formatDateTime, normalizeDateString } from '@hero/hero-utils/date';

interface LogListProps {
    logs: CRMProspectLog[];
}

const LogList: React.FC<LogListProps> = ({ logs }) => {
    const preparedArray = logs.sort((a, b) => {
        return new Date(b.created_at || '').getTime() - new Date(a.created_at || '').getTime();
    });

    return (
        <div
            style={{
                maxHeight: '400px',
                overflowY: 'auto',
                padding: '0.4rem',
                display: 'flex',
                flexDirection: 'column',
                rowGap: '2rem'
            }}
        >
            {preparedArray.map((log, index) => (
                <div
                    key={`${log.created_at}-${index}`}
                    style={{ display: 'flex', flexDirection: 'column', rowGap: '0.2rem' }}
                >
                    <div
                        style={{ display: 'flex', alignItems: 'center', columnGap: '0.8rem' }}
                        title={log.agent}
                    >
                        <P noDefaultMargin strong>
                            {log.action}
                        </P>
                        <div>•</div>
                        <P noDefaultMargin>
                            {log.created_at
                                ? formatDateTime(normalizeDateString(log.created_at, true))
                                : 'N/A'}
                        </P>
                    </div>
                    {log.log_message ? <P noDefaultMargin>{log.log_message}</P> : null}
                </div>
            ))}
        </div>
    );
};

export default LogList;
