import DefaultSelect from '@hero/ui-kit/inputs/DefaultSelect';
import Form from '@hero/ui-kit/inputs/Form';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { noop } from 'ts-essentials';
import * as yup from 'yup';

export const activeStatus = yup
    .object()
    .shape({
        active: yup.string().nullable()
    })
    .defined();

export type ActiveStatusParams = yup.InferType<typeof activeStatus>;

const ActiveFilter: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <Form
            validationSchema={activeStatus}
            submitFn={noop}
            defaultValues={{
                active: searchParams.get('active_only') || 'false'
            }}
        >
            <DefaultSelect
                styles={{ minWidth: '130px' }}
                name="active"
                onChange={(e) => {
                    const value = e.currentTarget.value;

                    setSearchParams({ active_only: value });
                }}
                options={[
                    { label: 'Active', value: 'true' },
                    { label: 'All', value: 'false' }
                ]}
            />
        </Form>
    );
};

export default ActiveFilter;
