import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import { useReactivationFlowContext } from '../context/ReactivationFlowProvider';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import Form from '@hero/ui-kit/inputs/Form';
import planPickerSchema from '../schema';
import Select from '@hero/ui-kit/inputs/Select';
import Option from '@hero/ui-kit/inputs/Option';
import formatDollarAmount from '@hero/hero-utils/currency';

interface ReactivateProps {}

export const ChangePlan: React.FC<ReactivateProps> = () => {
    const { selectPlan, planDetails } = useReactivationFlowContext();
    const marginTop = useComponentMargin({ marginTop: 'large' });

    return (
        <Form
            validationSchema={planPickerSchema}
            submitFn={selectPlan}
            defaultValues={{ plan: 'basic-extended-trial' }}
            render={(_, formState) => {
                return (
                    <>
                        <>
                            <Section
                                noDefaultPadding
                                styles={{ textAlign: 'left', ...marginTop }}
                                width="full"
                                centered
                            >
                                <P size="large" noDefaultMargin>
                                    Please choose plan to reactivate your membership
                                </P>
                            </Section>
                        </>
                        <Section centered noDefaultPadding paddingTop="regular">
                            <Select
                                name="plan"
                                displayName="Membership Plan"
                                styles={{ width: '42.4rem' }}
                                noExtraMargin
                                defaultValue="basic-extended-trial"
                            >
                                {planDetails.reactivationPlans.map((plan) => (
                                    <Option
                                        key={plan.storefront_key}
                                        value={plan.storefront_key || ''}
                                        labelText={`${plan.name} - ${formatDollarAmount(plan.price | 0, true)}`}
                                    />
                                ))}
                            </Select>
                        </Section>

                        <Section centered>
                            <Button width="large" type="submit" disabled={!formState.isValid}>
                                Continue
                            </Button>
                        </Section>
                    </>
                );
            }}
        />
    );
};
