import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { cancellationRefundPreview } from './queryKeys';
import envVars from '../../../constants/envVars';
import useQueryRequestParams from '../../../hooks/useQueryRequestParams';
const useGetReactivationPlanEligibility = (id, enabled) => {
    const additional = useQueryRequestParams();
    return useQuery({
        queryFn: () => axios.get(`${envVars.API_COMMERCE_HOSTNAME}subscription/backoffice/reactivate-plan-eligibility`, {
            ...additional,
            params: { id }
        }),
        queryKey: [cancellationRefundPreview, id],
        enabled
    });
};
export default useGetReactivationPlanEligibility;
