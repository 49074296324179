import React, { CSSProperties, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import { BackofficeMembershipDetails, PaymentUpdateParams } from '@hero/hero-types';
import {
    getUserCredit,
    addUserCredit,
    userCreditReset
} from '@hero/redux-data/backoffice/userCredit/actionCreators';
import {
    setMembershipPlan,
    membershipPlanReset
} from '@hero/redux-data/backoffice/membershipPlan/actionCreators';
import {
    updateUserPaymentMethod,
    userPaymentMethodReset
} from '@hero/redux-data/backoffice/userPaymentMethod/actionCreators';
import { membershipReactivationReset } from '@hero/redux-data/backoffice/membershipReactivation/actionCreators';
import {
    getUserDetails,
    userDetailsReset
} from '@hero/redux-data/backoffice/userDetails/actionCreators';
import {
    isUserCreditFetchingSelector,
    isUserCreditSavingSelector
} from '@hero/redux-data/backoffice/userCredit/selectors';
import {
    isMembershipPlanLoadingSelector,
    isMembershipPlanUpdatedSelector
} from '@hero/redux-data/backoffice/membershipPlan/selectors';
import { isUserPaymentMethodUpdatedSelector } from '@hero/redux-data/backoffice/userPaymentMethod/selectors';
import usePrevious from '@hero/react-hooks/usePrevious';
import useIsFirstMount from '@hero/react-hooks/useIsFirsMount';
import Section from '@hero/ui-kit/layout/Section';
import Loader from '@hero/ui-kit/components/Loader';
import Button from '@hero/ui-kit/inputs/Button';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import useComponentPadding from '@hero/ui-kit/hooks/useComponentPadding';
import { EditPlanParams } from '@hero/validators/forms/backoffice/editPlanSchema';
import { AddCreditParams } from '@hero/validators/forms/backoffice/addCreditSchema';
import getURLParams from '@hero/hero-utils/URLSearchParams';

import InlineLoader from '../../../components/InlineLoader';
import EditPlanModal from './EditPlanModal';
import AddCreditModal from './AddCreditModal';
import EditPaymentModal from './EditPaymentModal';
import Details from './Details';
import TabBar from '../TabBar';
import { MembershipRouteParams } from '../types';
import EditTrackingNumberModal from './EditTrackingNumberModal';
import ReactivationFlow from '../MembershipDetails/ReactivationFlow';
import {
    getCancellationDetails,
    cancellationDetailsReset
} from '@hero/redux-data/backoffice/cancellationDetails/actionCreators';
import {
    areCancellationDetailsLoadingSelector,
    cancellationDetailsSelector
} from '@hero/redux-data/backoffice/cancellationDetails/selectors';
import DefaultLayout from '../../../components/DefaultLayout';
import useGetMembershipDetails from '../api/useGetMembershipDetails';
import useGetUserDeviceDetails from '../../Devices/api/useGetUserDeviceDetails';
import useGetDetailViewTitleV2 from '../utils/useGetDetailViewTitleV2';

const MembershipDetailsInner: React.FC<{
    membership: BackofficeMembershipDetails | undefined;
    fetchMembershipDetails: () => void;
    isMembershipDetailsRefetching: boolean;
}> = ({ fetchMembershipDetails, membership, isMembershipDetailsRefetching }) => {
    const { id } = useParams<MembershipRouteParams>();
    const navigate = useNavigate();

    const {
        data: userDeviceResponse,
        mutate,
        isPending: isFetchingUserDevice
    } = useGetUserDeviceDetails();

    React.useEffect(() => {
        if (membership?.hero_user_id) {
            mutate({
                user_id: +membership.hero_user_id
            });
        } else if (!membership?.hero_user_id && membership?.serial) {
            mutate({
                external_serial: membership.serial
            });
        }
    }, [mutate, membership]);

    const userDevice = userDeviceResponse?.data;

    const dispatch = useDispatch();

    const isFetchingCredit = useSelector(isUserCreditFetchingSelector);
    const isSavingCredit = useSelector(isUserCreditSavingSelector);
    const isUpdatingPlan = useSelector(isMembershipPlanLoadingSelector);
    const isPlanUpdated = useSelector(isMembershipPlanUpdatedSelector);
    const isPaymentMethodUpdated = useSelector(isUserPaymentMethodUpdatedSelector);
    const cancellationDetails = useSelector(cancellationDetailsSelector, shallowEqual);
    const wasPaymentMethodUpdated = usePrevious(isPaymentMethodUpdated);
    const isFetchingCancellationDetails = useSelector(
        areCancellationDetailsLoadingSelector,
        shallowEqual
    );

    const isFirstMount = useIsFirstMount();

    const isFetching = isFetchingUserDevice || isFetchingCredit || isFetchingCancellationDetails;
    const isSaving = isUpdatingPlan || isSavingCredit;

    const [editPlanModalOpen, setEditPlanModalOpen] = useState(false);
    const [addCreditModalOpen, setAddCreditModalOpen] = useState(false);
    const [editPaymentModalOpen, setEditPaymentModalOpen] = useState(false);
    const [showTrackingNumberModal, setShowTrackingNumberModal] = useState(false);

    useEffect(() => {
        if (!id && membership?.id) {
            navigate(`/membership/${membership.id}/details`, { replace: true });
        }
    }, [navigate, id, membership]);

    useEffect(() => {
        if (isPlanUpdated && id) {
            fetchMembershipDetails();
        }
    }, [isPlanUpdated, fetchMembershipDetails, id]);

    useEffect(() => {
        if (membership?.status === 'cancelled') {
            dispatch(getCancellationDetails({ id: membership.id }));
        }
    }, [dispatch, membership]);

    useEffect(() => {
        if (!isFirstMount) {
            if (
                membership?.id &&
                membership?.status !== 'not-activated' &&
                membership?.status !== 'cancelled'
            ) {
                dispatch(getUserCredit({ id: membership.id }));
            }
        }
    }, [dispatch, isFirstMount, membership]);

    useEffect(() => {
        const user_id = membership?.scheduled_plan_selected_by || 0;
        user_id > 0 && dispatch(getUserDetails({ user_id }));
    }, [membership?.scheduled_plan_selected_by, dispatch]);

    useEffect(
        () => () => {
            dispatch(userCreditReset());
            dispatch(membershipPlanReset());
            dispatch(userPaymentMethodReset());
            dispatch(membershipReactivationReset());
            dispatch(userDetailsReset());
            dispatch(cancellationDetailsReset());
        },
        [dispatch]
    );

    useEffect(() => {
        if (!wasPaymentMethodUpdated && isPaymentMethodUpdated && id) {
            fetchMembershipDetails();
        }
    }, [fetchMembershipDetails, id, wasPaymentMethodUpdated, isPaymentMethodUpdated]);

    const handleEditPlanAction = useCallback(() => {
        setEditPlanModalOpen(true);
    }, []);

    const handleAddCreditAction = useCallback(() => {
        setAddCreditModalOpen(true);
    }, []);

    const handleEditPaymentAction = useCallback(() => {
        setEditPaymentModalOpen(true);
    }, []);

    const handleEditTrackingNumber = useCallback(() => {
        setShowTrackingNumberModal(true);
    }, []);

    const handleEditTrackingNumberConfirm = useCallback(() => {
        membership?.id && fetchMembershipDetails();
    }, [fetchMembershipDetails, membership]);

    const handleEditPlanSubmit = useCallback(
        ({ plan_id }: EditPlanParams) => {
            id && dispatch(setMembershipPlan({ id: +id, plan_id: +plan_id }));
            setEditPlanModalOpen(false);
        },
        [dispatch, id]
    );

    const handleAddCreditSubmit = useCallback(
        ({ amount, note }: AddCreditParams) => {
            const creditAmount = Math.round(amount * -100);
            id && dispatch(addUserCredit({ id: +id, amount: creditAmount, note: note.trim() }));
            setAddCreditModalOpen(false);
        },
        [dispatch, id]
    );

    const handleEditPaymentSubmit = useCallback(
        (formValues: PaymentUpdateParams) => {
            id && dispatch(updateUserPaymentMethod({ id: +id, ...formValues }));
            setEditPaymentModalOpen(false);
        },
        [dispatch, id]
    );

    const handleBackToList = useCallback(() => {
        navigate('/membership', { replace: true });
    }, [navigate]);

    const headerTitle = useGetDetailViewTitleV2(userDevice, membership);

    const innerWrapPadding = useComponentPadding({ paddingVertical: 'large' });

    const headerWrapStyles: CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    };
    const contentStyles: CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    };

    const [openReactivationFlow, setOpenReactivationFlow] = useState(false);

    const handleOpenReactivateFlow = React.useCallback(() => {
        setOpenReactivationFlow(true);
    }, []);

    const isReactivationFlowDisabled = React.useMemo<boolean>(() => {
        // sometimes BE will not return proper data because ORG/DTC user switch
        const checkRma = cancellationDetails?.rma
            ? cancellationDetails?.rma?.return_status !== 1
            : false;
        const checkSentToCollectionAgency = cancellationDetails?.cancellation
            ? cancellationDetails?.cancellation?.sent_to_collection_agency
            : false;

        return !membership?.serial || checkRma || checkSentToCollectionAgency;
    }, [membership, cancellationDetails]);

    return isSaving ? (
        <Loader />
    ) : (
        <DefaultLayout>
            {editPlanModalOpen && (
                <EditPlanModal
                    onSubmit={handleEditPlanSubmit}
                    externalControls={[editPlanModalOpen, setEditPlanModalOpen]}
                />
            )}
            {addCreditModalOpen && (
                <AddCreditModal
                    onSubmit={handleAddCreditSubmit}
                    externalControls={[addCreditModalOpen, setAddCreditModalOpen]}
                />
            )}
            {editPaymentModalOpen && (
                <EditPaymentModal
                    onSubmit={handleEditPaymentSubmit}
                    externalControls={[editPaymentModalOpen, setEditPaymentModalOpen]}
                />
            )}
            {showTrackingNumberModal ? (
                <EditTrackingNumberModal
                    orderId={membership?.order_id || undefined}
                    onConfirm={handleEditTrackingNumberConfirm}
                    externalControls={[showTrackingNumberModal, setShowTrackingNumberModal]}
                />
            ) : null}
            <Section
                subtheme={['regular', 'background']}
                paddingVertical="small"
                paddingHorizontal="regular"
            >
                <div style={headerWrapStyles}>
                    <H role="h5">{headerTitle}</H>
                    <Button variant="secondary" onClick={handleBackToList} noDefaultMargin>
                        Back To List
                    </Button>
                </div>
                <TabBar />
                <div style={innerWrapPadding}>
                    {isFetching ? (
                        <InlineLoader />
                    ) : !membership ? (
                        <P>No membership data available.</P>
                    ) : (
                        <div style={contentStyles}>
                            <Details
                                userDevice={userDevice}
                                membership={membership}
                                onEditPlan={handleEditPlanAction}
                                onAddCredit={handleAddCreditAction}
                                onEditPayment={handleEditPaymentAction}
                                onEditTrackingNumber={handleEditTrackingNumber}
                                fetchMembershipDetails={fetchMembershipDetails}
                            />
                            {membership.status === 'cancelled' && (
                                <Button
                                    onClick={handleOpenReactivateFlow}
                                    noDefaultMargin
                                    disabled={isReactivationFlowDisabled}
                                >
                                    Resume Membership
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            </Section>
            {openReactivationFlow && membership ? (
                <ReactivationFlow
                    membership={membership}
                    membershipId={membership.id}
                    externalControls={[openReactivationFlow, setOpenReactivationFlow]}
                    cancellationDetails={cancellationDetails || undefined}
                    fetchMembershipDetails={fetchMembershipDetails}
                    isMembershipDetailsRefetching={isMembershipDetailsRefetching}
                />
            ) : null}
        </DefaultLayout>
    );
};

const MembershipDetails: React.FC = () => {
    const { id } = useParams<MembershipRouteParams>();
    const { search } = useLocation();
    const serial = getURLParams(search, 'serial');
    const userId = getURLParams(search, 'user_id');

    const {
        data: membershipResponse,
        isLoading,
        isFetching: isMembershipDetailsRefetching,
        refetch: fetchMembershipDetails
    } = useGetMembershipDetails(
        {
            ...(id && { id: +id }),
            ...(serial && { device_serial: serial }),
            ...(userId && { hero_user_id: userId })
        },
        !!id || !!serial || !!userId
    );

    const membership = membershipResponse?.data.data;

    if (isLoading) {
        return (
            <DefaultLayout>
                <InlineLoader />
            </DefaultLayout>
        );
    }

    return (
        <MembershipDetailsInner
            isMembershipDetailsRefetching={isMembershipDetailsRefetching}
            membership={membership}
            fetchMembershipDetails={fetchMembershipDetails}
        />
    );
};

export default MembershipDetails;
