import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import DatePicker from '@hero/ui-kit/components/DatePicker';
import Button from '@hero/ui-kit/inputs/Button';
import Form, { useFormContext } from '@hero/ui-kit/inputs/Form';
import TextArea from '@hero/ui-kit/inputs/TextArea';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import React from 'react';

import * as yup from 'yup';
import useProspectAction from '../../../api/useProspectAction';
import InlineLoader from '../../../../../components/InlineLoader';
import QueryInlineError from '../../../../../components/QueryInlineError';
import * as Style from './style.module.css';
import { format } from 'date-fns';

const postponeSLATimerSchema = yup
    .object()
    .shape({
        sla_date: yup.string().required('SLA date is required'),
        note: yup.string().nullable().notRequired()
    })
    .defined();

export type PostponeSLATimerParams = yup.InferType<typeof postponeSLATimerSchema>;

const FormElements: React.FC<{ date: Date | null; onChangeDate: (date: Date | null) => void }> = ({
    date,
    onChangeDate
}) => {
    const { setValue } = useFormContext();

    return (
        <>
            <DatePicker
                selected={date}
                onChange={(date) => {
                    onChangeDate(date);

                    if (date) {
                        // format must be like 2025-02-14 12:00:00
                        const formattedDate = format(date, 'yyyy-MM-dd HH:mm:ss');

                        setValue('sla_date', formattedDate);
                    }
                }}
                showTimeInput
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy H:mm"
                className={Style.datePicker}
            />
            <div style={{ marginTop: '1.6rem' }}>
                <TextArea name="note" displayName="New Note" cols={40} rows={8} />
            </div>
        </>
    );
};

type PostponeSLATimerModalProps = {
    onCancel?: () => void;
    prospectId: string;
} & Pick<ModalProps, 'externalControls'>;

const PostponeSLATimerModal: React.FC<PostponeSLATimerModalProps> = ({
    externalControls,
    onCancel,
    prospectId
}) => {
    const [, setExternalState] = externalControls ?? [];
    const [date, setDate] = React.useState<Date | null>(new Date());

    const {
        mutateAsync: prospectAction,
        isPending: isActionPending,
        error,
        isSuccess,
        reset
    } = useProspectAction();

    const handleCancel = () => {
        onCancel?.();
        setExternalState && setExternalState(false);
        reset();
    };

    const handleSubmit = (values: PostponeSLATimerParams) => {
        prospectAction({
            prospect_id: prospectId,
            sla_date: values.sla_date, // format 2025-02-14 12:00:00
            action: 'postpone_sla',
            ...(values.note && { note: values.note })
        });
    };

    return (
        <Modal
            isCancelable
            onClose={handleCancel}
            externalControls={externalControls}
            type={isSuccess ? 'success' : 'neutral'}
        >
            <P centered strong size="large">
                Postpone SLA Timer
            </P>

            <Section centered noDefaultPadding>
                {isSuccess ? (
                    <>
                        <P size="large" centered>
                            SLA date successfully postponed.
                        </P>
                        <Button type="button" variant="outlineSecondary" onClick={handleCancel}>
                            Close
                        </Button>
                    </>
                ) : (
                    <>
                        {isActionPending ? (
                            <InlineLoader />
                        ) : (
                            <Form
                                validationSchema={postponeSLATimerSchema}
                                submitFn={handleSubmit}
                                defaultValues={{
                                    sla_date: format(new Date(), 'yyyy-MM-dd HH:mm:ss')
                                }}
                            >
                                <FormElements date={date} onChangeDate={setDate} />
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        columnGap: '1rem',
                                        marginTop: '1.6rem',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Button
                                        type="button"
                                        variant="outlineSecondary"
                                        onClick={handleCancel}
                                        noDefaultMargin
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        disabled={isActionPending}
                                        noDefaultMargin
                                    >
                                        Submit
                                    </Button>
                                </div>

                                {error ? <QueryInlineError centered error={error} /> : null}
                            </Form>
                        )}
                    </>
                )}
            </Section>
        </Modal>
    );
};

export default PostponeSLATimerModal;
