import Button from '@hero/ui-kit/inputs/Button';
import Form, { useFormContext } from '@hero/ui-kit/inputs/Form';
import TextArea from '@hero/ui-kit/inputs/TextArea';
import Section from '@hero/ui-kit/layout/Section';
import React from 'react';

import * as yup from 'yup';
import useProspectAction from '../../api/useProspectAction';
import QueryInlineError from '../../../../components/QueryInlineError';
import { toast } from 'sonner';

const newNoteSchema = yup
    .object()
    .shape({
        note: yup.string().required('Note is required')
    })
    .defined();

export type NewNoteSchemaParams = yup.InferType<typeof newNoteSchema>;

const FormElements: React.FC<{ isActionPending: boolean; disabled?: boolean }> = ({
    isActionPending,
    disabled
}) => {
    const {
        formState: { isValid },
        reset
    } = useFormContext();

    return (
        <>
            <TextArea name="note" displayName="New Note" rows={5} cols={50} />
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '1rem'
                }}
            >
                <Button variant="outlineSecondary" type="button" onClick={() => reset()}>
                    Clear
                </Button>
                <Button type="submit" disabled={isActionPending || !isValid || disabled}>
                    {isActionPending ? 'Saving...' : 'Save'}
                </Button>
            </div>
        </>
    );
};

type AddNoteFormProps = {
    prospectId: string;
    disabled?: boolean;
};

const AddNoteForm: React.FC<AddNoteFormProps> = ({ prospectId, disabled }) => {
    const { mutateAsync: prospectAction, isPending: isActionPending, error } = useProspectAction();

    const handleSubmit = (values: NewNoteSchemaParams) => {
        prospectAction({
            prospect_id: prospectId,
            action: 'add_note',
            note: values.note
        }).then(() => {
            toast.success('Note added successfully');
        });
    };

    return (
        <Section noDefaultPadding styles={{ maxWidth: '60rem' }}>
            <Form validationSchema={newNoteSchema} submitFn={handleSubmit}>
                <FormElements disabled={disabled} isActionPending={isActionPending} />
                {error ? <QueryInlineError centered error={error} /> : null}
            </Form>
        </Section>
    );
};

export default AddNoteForm;
