import React from 'react';
import { useSearchParams } from 'react-router-dom';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import DotsMoreIcon from '@hero/ui-kit/icons/utility/DotsMoreIcon';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import CheckIcon from '@hero/ui-kit/icons/utility/CheckIcon';
import * as Style from './style.module.css';
import { calcCurrentPage } from '@hero/ui-kit/tables/Pager/usePagination/utils';

interface LimitItemsProps {
    limit?: number;
    total_count?: number;
    offset?: number;
    className?: string;
    isLoading?: boolean;
    limitItems?: number[];
}

const LimitItems: React.FC<LimitItemsProps> = ({
    limit,
    total_count,
    offset,
    className,
    isLoading = false,
    limitItems = [20, 50, 100]
}) => {
    const [_, setSearchParams] = useSearchParams();
    const [showLimitBox, setShowLimitBox] = React.useState(false);

    const limits = limitItems.map((item) => ({
        label: `${item} items`,
        value: item,
        isSelected: limit === item
    }));

    const handleLimitChange = (limit: number) => {
        setSearchParams((prev) => {
            prev.set('limit', `${limit}`);
            return prev;
        });
    };

    if (isLoading || !limit || !total_count) {
        return <div style={{ width: '12rem' }} />;
    }

    return limit > 0 && total_count > 0 ? (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                columnGap: '1rem',
                fontSize: '1.4rem',
                minHeight: '4rem',
                margin: '6px 0px 24px'
            }}
            className={className}
        >
            {`${calcCurrentPage(offset || 0, limit)}-${
                limit > total_count ? total_count : limit
            } of ${total_count}`}
            <div className={Style.filterStatus}>
                <ClickableOpacity
                    className={`${Style.filterStatusToggle} ${
                        showLimitBox ? Style.openLimitBox : ''
                    }`}
                    alt="expand items limiter"
                    onClick={() => setShowLimitBox((prev) => !prev)}
                >
                    <DotsMoreIcon width={36} height={36} size="large" />
                </ClickableOpacity>
                {showLimitBox && (
                    <Section
                        noDefaultPadding
                        className={`${Style.filterStatusDropdown}`}
                        border={true}
                    >
                        <>
                            <P strong noDefaultMargin className={Style.headerLabel}>
                                Show up to
                            </P>
                            {limits.map((limit) => {
                                return (
                                    <ClickableOpacity
                                        fullWidth
                                        key={limit.label}
                                        alt={`Toggle ${limit.label}`}
                                        className={`${Style.filterStatusItem} ${
                                            limit.isSelected ? Style.selected : ''
                                        }`}
                                        onClick={() => {
                                            handleLimitChange(limit.value);
                                        }}
                                    >
                                        <CheckIcon
                                            size="small"
                                            type="brand"
                                            styles={{
                                                visibility: limit.isSelected ? 'visible' : 'hidden'
                                            }}
                                        />
                                        <P noDefaultMargin>{limit.label}</P>
                                    </ClickableOpacity>
                                );
                            })}
                        </>
                    </Section>
                )}
            </div>
        </div>
    ) : null;
};

export default LimitItems;
