const getStatusLabel = (prospect) => {
    if (prospect.deactivated) {
        return 'Deactivated';
    }
    if (prospect.prospect_type === 'RTM') {
        const provider_name = prospect.provider_hero_organization || 'N/A';
        const rtmStatus = prospect.rtm_prospect_status
            ? prospect.rtm_prospect_status.replaceAll('_', ' ').toLowerCase()
            : 'N/A';
        const additionalInfo = ['rejected', 'expired'].includes(rtmStatus)
            ? ` by ${provider_name}`
            : '';
        return `RTM ${rtmStatus}${additionalInfo}`;
    }
    return 'D2C prospect';
};
export default getStatusLabel;
