import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import Button from '@hero/ui-kit/inputs/Button';
import DefaultSelect from '@hero/ui-kit/inputs/DefaultSelect';
import Form from '@hero/ui-kit/inputs/Form';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import React from 'react';

import * as yup from 'yup';
import useProspectAction from '../../../api/useProspectAction';
import InlineLoader from '../../../../../components/InlineLoader';
import QueryInlineError from '../../../../../components/QueryInlineError';
import { ProspectCommuncation } from '../../../types';

const sendCommunicationSchema = yup
    .object()
    .shape({
        communication_type: yup.lazy((val) =>
            !val
                ? yup.string().nullable().notRequired()
                : yup.string().oneOf(['D2C', 'RTM', 'RTM + DTC'], 'Invalid communication type')
        ) as unknown as yup.StringSchema<'D2C' | 'RTM' | 'RTM + DTC', object>,
        communication_channel: yup.lazy((val) =>
            !val
                ? yup.string().nullable().notRequired()
                : yup.string().oneOf(['Email', 'SMS'], 'Invalid communication channel')
        ) as unknown as yup.StringSchema<'Email' | 'SMS', object>
    })
    .defined();

export type SendCommunicationParams = yup.InferType<typeof sendCommunicationSchema>;

type SendCommunicationModalProps = {
    onCancel?: () => void;
    prospectId: string;
    communication?: ProspectCommuncation;
} & Pick<ModalProps, 'externalControls'>;

const SendCommunicationModal: React.FC<SendCommunicationModalProps> = ({
    externalControls,
    onCancel,
    prospectId,
    communication
}) => {
    const [, setExternalState] = externalControls ?? [];

    const {
        mutateAsync: prospectAction,
        isPending: isActionPending,
        error,
        isSuccess,
        reset
    } = useProspectAction();

    const handleCancel = () => {
        onCancel?.();
        setExternalState && setExternalState(false);
        reset();
    };

    const channels =
        communication?.channels.map((channel) => {
            if (channel === 'EMAIL') {
                return { label: 'Email', value: 'Email' };
            }
            if (channel === 'SMS') {
                return { label: 'SMS', value: 'SMS' };
            }

            return { label: channel, value: channel };
        }) || [];

    const types =
        communication?.types.map((type) => {
            if (type === 'D2C') {
                return { label: 'DTC Offer', value: 'D2C' };
            }
            if (type === 'RTM') {
                return { label: 'RTM Offer', value: 'RTM' };
            }
            if (type === 'RTM_DTC') {
                return { label: 'DTC + RTM Offer', value: 'RTM + DTC' };
            }

            return { label: type, value: type };
        }) || [];

    const handleSubmit = (values: SendCommunicationParams) => {
        prospectAction({
            prospect_id: prospectId,
            action: 'send_communication',
            communication_channel: values.communication_channel,
            communication_type: values.communication_type
        });
    };

    return (
        <Modal
            isCancelable
            onClose={handleCancel}
            externalControls={externalControls}
            type={isSuccess ? 'success' : 'neutral'}
            styles={{ minWidth: '40rem', width: '40rem' }}
        >
            <P centered strong size="large">
                Send communication
            </P>

            <Section centered noDefaultPadding>
                {isSuccess ? (
                    <>
                        <P size="large" centered>
                            Send communication action is successful.
                        </P>
                        <Button type="button" variant="outlineSecondary" onClick={handleCancel}>
                            Close
                        </Button>
                    </>
                ) : (
                    <>
                        {isActionPending ? (
                            <InlineLoader />
                        ) : (
                            <Form
                                validationSchema={sendCommunicationSchema}
                                submitFn={handleSubmit}
                                defaultValues={{
                                    communication_type: 'D2C',
                                    communication_channel: 'Email'
                                }}
                            >
                                <DefaultSelect
                                    name="communication_type"
                                    options={types}
                                    styles={{ minWidth: '30rem' }}
                                />
                                <DefaultSelect name="communication_channel" options={channels} />
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        columnGap: '1rem',
                                        marginTop: '1.6rem'
                                    }}
                                >
                                    <Button
                                        type="button"
                                        variant="outlineSecondary"
                                        onClick={handleCancel}
                                        noDefaultMargin
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        disabled={isActionPending}
                                        noDefaultMargin
                                    >
                                        Submit
                                    </Button>
                                </div>

                                {error ? <QueryInlineError centered error={error} /> : null}
                            </Form>
                        )}
                    </>
                )}
            </Section>
        </Modal>
    );
};

export default SendCommunicationModal;
