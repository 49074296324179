// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ymu_ba3a0RA3SG6P7fdz {
    width: 34rem !important;
    min-width: 34rem !important;
}`, "",{"version":3,"sources":["webpack://./pages/CRM/components/Details/modals/style.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,2BAA2B;AAC/B","sourcesContent":[".datePicker {\n    width: 34rem !important;\n    min-width: 34rem !important;\n}"],"sourceRoot":""}]);
// Exports
export var datePicker = `Ymu_ba3a0RA3SG6P7fdz`;
export default ___CSS_LOADER_EXPORT___;
