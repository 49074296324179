import * as React from 'react';
import P from '@hero/ui-kit/typography/P';
import H from '@hero/ui-kit/typography/H';
import Button from '@hero/ui-kit/inputs/Button';
import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';

type ConfirmationModalProps = {
    onConfirm: () => void;
    title: string;
    description: string;
    confirmBtnLabel?: string;
    cancelBtnLabel?: string;
    onCancel?: () => void;
    disabled?: boolean;
} & Pick<ModalProps, 'externalControls'>;

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    onConfirm,
    onCancel,
    externalControls,
    title,
    description,
    confirmBtnLabel = 'Confirm',
    cancelBtnLabel = 'Cancel',
    disabled = false
}) => {
    const [, setExternalState] = externalControls ?? [];

    const condirmModalStyles: React.CSSProperties = {
        width: '100rem',
        maxWidth: '100rem',
        height: '35rem',
        padding: '10rem'
    };

    const handleCloseConfirm = React.useCallback(() => {
        setExternalState && setExternalState(false);
        onCancel && onCancel();
    }, [onCancel, setExternalState]);

    return (
        <Modal
            type="warn"
            isCancelable
            externalControls={externalControls}
            styles={condirmModalStyles}
            onClose={onCancel}
        >
            <H role="h4">{title}</H>
            <P>{description}</P>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingTop: '48px'
                }}
            >
                <Button
                    disabled={disabled}
                    variant="inverted"
                    onClick={onConfirm}
                    styles={{ width: '154px', height: '60px', marginRight: '48px' }}
                >
                    {confirmBtnLabel}
                </Button>
                <Button disabled={disabled} variant="secondary" onClick={handleCloseConfirm}>
                    {cancelBtnLabel}
                </Button>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
