import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import useComponentMargin from '@hero/ui-kit/hooks/useComponentMargin';
import { useReactivationFlowContext } from '../context/ReactivationFlowProvider';

export const Fail = () => {
    const { onCloseReactivationFlow, failDetails, goToFOP } =
        useReactivationFlowContext();
    const marginTop = useComponentMargin({ marginTop: 'small' });

    return (
        <>
            <H role="h6" centered>
                {failDetails.header}
            </H>
            <Section
                noDefaultPadding
                styles={{ textAlign: 'left', ...marginTop }}
                width="full"
                centered
            >
                <P size="large" noDefaultMargin strong styles={marginTop}>
                    Reason:
                </P>
                <P size="large" noDefaultMargin>
                    {failDetails.message}
                </P>
            </Section>
            <Section centered>
                <Button size="regular" onClick={goToFOP}>
                    Add new credit card
                </Button>
                <br />
                <Button size="regular" onClick={onCloseReactivationFlow} variant="inverted">
                    Cancel Reactivation
                </Button>
            </Section>
        </>
    );
};
